import { Box, styled, Typography } from '@mui/material'

const BreadCrumbText = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    maxWidth: '200px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '150px',
  },
}))

const BreadcrumbBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  gap: '8px',
}))

export { BreadCrumbText, BreadcrumbBox }
