import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  right: '15px',
  top: '8px',
  color: theme.palette.white,
  padding: '5px',
  fontSize: '35px',
  cursor: 'pointer',
}))

export { StyledCloseIcon }
