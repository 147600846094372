import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // Correct storage import
import topBarReducer from '../slice/TopBar' // Make sure the reducer import is correct
import auth from '../slice/Auth'
import userProjectsReducer from '../slice/UserProjects'
import commonReducer from '../slice/common'
import conversationReducer from '../slice/Conversation'
import userSettingsReducer from '../slice/UserSettings'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['TopBar', 'common', 'UserProjects'], // Specify which reducers to persist (adjust if needed)
}

const rootReducer = combineReducers({
  TopBar: topBarReducer,
  auth: auth.reducer,
  UserProjects: userProjectsReducer,
  common: commonReducer,
  conversations: conversationReducer,
  UserSettings: userSettingsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
})

const persistor = persistStore(store)

export { store, persistor }
