import React, { useState, useEffect } from 'react'
import {
  Box,
  Slide,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  Popover,
} from '@mui/material'
import ModalAddFunction from '../modal/ModalAddFunction'
import InfoIcon from '@mui/icons-material/Info'
import SliderBox from '../slider/index'
import { Heading, ParameterDrawer, StyledBox } from './studioParamsStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  setJsonMode,
  setParameters,
  setParametersCompare,
  setResponseFormat,
  setResponseFormatCompare,
  setJsonModeCompare,
} from '../../redux/slice/Conversation'
import {
  GEMINI_JSON_EXAMPLE,
  OPENAI_JSON_EXAMPLE,
  PARAM_SETTINGS,
} from '../../constants/Constants'
import { toast } from 'react-toastify'
import { useAuth } from '../../AuthContext'
import Ajv from 'ajv'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} timeout={400} />
})

const StudioParams = ({ component, openParam, onClose, category }) => {
  const [open, setOpen] = useState(false)
  // const [jsonMode, setJsonMode] = useState(false)

  const handleClose = () => setOpen(false)
  const {
    parameters,
    parametersCompare,
    selectedAi,
    selectedAiCompare,
    responseFormat,
    jsonMode,
    jsonModeCompare,
    responseFormatCompare,
  } = useSelector((state) => state.conversations)
  const dispatch = useDispatch()
  // const model = 'gemini'
  const { isPublished } = useSelector((state) => state.conversations)

  const [jsonDialogOpen, setJsonDialogOpen] = useState(false)
  // const [jsonInput, setJsonInput] = useState('')
  const [jsonError, setJsonError] = useState('') // Add state for JSON error
  const [localJsonInput, setLocalJsonInput] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    // Initialize local state with the current value from Redux
    setLocalJsonInput(component === 2 ? responseFormatCompare : responseFormat)
  }, [component, responseFormatCompare, responseFormat, jsonDialogOpen])

  const handleJsonModeToggle = () => dispatch(setJsonMode(!jsonMode))
  const handleJsonModeToggleCompare = () => dispatch(setJsonModeCompare(!jsonModeCompare))
  const handleJsonDialogOpen = () => {
    setJsonDialogOpen(true)
    setJsonError('')
  }
  const handleJsonDialogClose = () => setJsonDialogOpen(false)

  

  // Add this function to validate the JSON schema
  const validateJsonSchemaOpenai = (schema) => {
    const ajv = new Ajv()
    const validate = ajv.compile({
      type: 'object',
      properties: {
        name: { type: 'string' },
        strict: { type: 'boolean' },
        schema: {
          type: 'object',
          properties: {
            type: { const: 'object' },
            properties: { type: 'object' },
            required: { type: 'array', items: { type: 'string' } },
            additionalProperties: { type: 'boolean' }
          },
          required: ['type', 'properties'],
          additionalProperties: true
        }
      },
      required: ['schema'],
      additionalProperties: false
    })
  
    const valid = validate(schema)
    if (!valid) {
      throw new Error(ajv.errorsText(validate.errors))
    }
  }

  // Add this function after the validateJsonSchemaOpenai function

  const validateJsonSchemaGemini = (schema) => {
    const ajv = new Ajv()
    const validate = ajv.compile({
      type: 'object',
      properties: {
        type: { type: 'string', enum: ['object', 'array'] },
        properties: { 
          type: 'object',
          additionalProperties: {
            type: 'object',
            properties: {
              type: { type: 'string' },
              description: { type: 'string' },
              format: { type: 'string' },
              enum: { type: 'array' },
              minimum: { type: 'number' },
              maximum: { type: 'number' },
              items: { type: 'object' }
            },
            additionalProperties: true
          }
        },
        required: { type: 'array', items: { type: 'string' } },
        additionalProperties: { type: 'boolean' }
      },
      required: ['type', 'properties'],
      additionalProperties: false
    })

    const valid = validate(schema)
    if (!valid) {
      throw new Error(ajv.errorsText(validate.errors))
    }
  }

  // Modify the handleJsonSave function
  const handleJsonSave = async () => {
    try {
      // Parse the JSON input
      const parsedInput = JSON.parse(localJsonInput)

      let response_format
      if (component === 2 ? selectedAiCompare === 'openai' : selectedAi === 'openai') {
        // For OpenAI, we need to validate and use the entire input
        validateJsonSchemaOpenai(parsedInput)
        response_format = parsedInput;
      } else if (component === 2 ? selectedAiCompare === 'gemini' : selectedAi === 'gemini') {
        // For Gemini, validate the schema
        validateJsonSchemaGemini(parsedInput)
        response_format = parsedInput
      }

      // Update Redux state
      if (component === 2) {
        dispatch(setResponseFormatCompare(JSON.stringify(parsedInput)))
      } else {
        dispatch(setResponseFormat(JSON.stringify(parsedInput)))
      }
      setJsonDialogOpen(false)
      toast.success('JSON schema saved successfully')
    } catch (error) {
      console.error('Error saving JSON:', error)
      toast.error(`Error: ${error.message}. Please check your JSON schema and try again.`)
    }
  }

  const handleJsonFormat = () => {
    try {
      const trimmedInput = localJsonInput.trim()
      const parsedJson = JSON.parse(trimmedInput)
      const formattedJson = JSON.stringify(parsedJson, null, 2)
      setLocalJsonInput(formattedJson)
      setJsonError('')
    } catch (error) {
      setJsonError('Invalid JSON input')
    }
  }

  const handleChange = (name, val) => {
    if (component === 2) {
      const newParametersCompare = {
        ...parametersCompare,
        [selectedAiCompare]: {
          ...parametersCompare[selectedAiCompare],
          [name]: val,
        },
      }
      dispatch(setParametersCompare(newParametersCompare))
    } else {
      const newParameters = {
        ...parameters,
        [selectedAi]: {
          ...parameters[selectedAi],
          [name]: val,
        },
      }
      dispatch(setParameters(newParameters))
    }
  }

  const sliderSettings = PARAM_SETTINGS

  // Get the settings and values for the current model
  let currentSettings
  if (component === 2) {
    currentSettings = sliderSettings[selectedAiCompare] || {}
  } else {
    currentSettings = sliderSettings[selectedAi] || {}
  }

  // const currentValues = settings[model] || {}
  let currentValues
  if (component === 2) {
    currentValues = parametersCompare[selectedAiCompare] || {}
  } else {
    currentValues = parameters[selectedAi] || {}
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  return (
    <ParameterDrawer
      anchor="right"
      open={openParam}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <StyledBox>
        <Heading>Configurations</Heading>
        {((component === 1 && ((selectedAi === 'openai' && !category.includes('gpt-3.5-turbo')) || selectedAi === 'gemini')) ||
          (component === 2 && ((selectedAiCompare === 'openai' && !category.includes('gpt-3.5-turbo')) || selectedAiCompare === 'gemini'))) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            width="100%"
          >
            <Box sx={{ flex: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={component === 1 && isPublished}
                    checked={component === 2 ? jsonModeCompare : jsonMode}
                    onChange={component === 2 ? handleJsonModeToggleCompare : handleJsonModeToggle}
                  />
                }
                // label={jsonMode ? 'Json Mode On' : 'Json Mode Off'}
                label="Json Mode"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              {((component === 1 && jsonMode) || (component === 2 && jsonModeCompare)) && (
                <Button onClick={handleJsonDialogOpen}>Edit Schema</Button>
              )}
            </Box>
          </Stack>
        )}

        <ModalAddFunction open={open} handleClose={handleClose} />
        {currentSettings && (
          <>
            {Object.keys(currentSettings)?.map((key) => (
              <SliderBox
                key={key}
                name={key}
                val={currentValues[key]}
                handleValChange={(val) => handleChange(key, val)}
                isPublished={component === 2 ? false : isPublished}
                min={currentSettings[key].min}
                max={currentSettings[key].max}
                step={currentSettings[key].step}
              />
            ))}
          </>
        )}
      </StyledBox>
        {/* JSON Schema Dialog */}
      <Dialog
        open={jsonDialogOpen}
        // onClose={handleJsonDialogClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            height: '80vh',
            width: '80vw',
          },
        }}
      >
        <DialogTitle>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h6">Edit Response Format</Typography>
              <IconButton size="small" onClick={handlePopoverOpen}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Stack>
            <Typography variant="body2">
              Use a JSON schema to define the structure of the model's response format
            </Typography>
          </Stack>

          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography
              sx={{
                p: 2,
                maxWidth: 400,
                whiteSpace: 'pre-wrap',
                fontFamily: 'monospace',
                fontSize: '14px',
              }}
            >
              {component === 1
                ? (selectedAi === 'openai' ? OPENAI_JSON_EXAMPLE : selectedAi === "gemini" ? GEMINI_JSON_EXAMPLE : '')
                : (selectedAiCompare === 'openai' ? OPENAI_JSON_EXAMPLE : selectedAiCompare === "gemini" ? GEMINI_JSON_EXAMPLE : '')
              }
            </Typography>
          </Popover>
        </DialogTitle>
        <DialogContent>
          {jsonError && <Typography color="error">{jsonError}</Typography>}
          <TextField
            autoFocus
            margin="dense"
            id="jsonInput"
            type="text"
            fullWidth
            multiline
            rows={20}
            value={localJsonInput}
            onChange={(e) => setLocalJsonInput(e.target.value)}
            disabled={component === 1 && isPublished}
            InputProps={{
              style: {
                fontFamily: 'monospace',
                whiteSpace: 'pre',
              },
            }}
          />

          <Button onClick={handleJsonFormat}>Format JSON</Button>
          {/* Conditionally render error message */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJsonDialogClose}>Cancel</Button>
          {((component === 1 && !isPublished) || component === 2) && (
            <Button onClick={handleJsonSave}>Save</Button>
          )}
        </DialogActions>
      </Dialog>
    </ParameterDrawer>
  )
}

export default StudioParams