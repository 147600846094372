export const authApi = {
  login: '/api/v1/login',
  refreshToken: 'api/v1/token/refresh',
}

export const userProjectApi = {
  userProjectDetails: (userId) => `api/v1/get/all/projects/${userId}`,

  singleProjectDetails: (userId, projectId) => `api/v1/get/selected/project/${userId}/${projectId}`,

  createDefaultProject: (userId) => `api/v1/post/default/project/${userId}`,

  createProject: (userId) => `api/v1/post/create/project/${userId}`,

  updateProjectName: (userId, projectId) => `api/v1/update/selected/project/${userId}/${projectId}`,

  deactivateProject: (userId, projectId) => `api/v1/delete/selected/project/${userId}/${projectId}`,

  deactivateFolder: (userId, projectId, folderId) =>
    `api/v1/delete/selected/folder/${userId}/${projectId}/${folderId}`,

  deactivatePrompt: (userId, projectId, folderId, promptId) =>
    `api/v1/delete/selected/prompt/${userId}/${projectId}/${folderId}/${promptId}`,

  updateFolderName: (userId, projectId, folderId) =>
    `api/v1/update/selected/folder/${userId}/${projectId}/${folderId}`,

  updatePromptName: (userId, projectId, folderId, promptId) =>
    `api/v1/update/selected/prmopt/${userId}/${projectId}/${folderId}/${promptId}`,

  createFolder: (userId, projectId) => `api/v1/post/create/folder/${userId}/${projectId}`,

  createPrompt: (userId, projectId, folderId) =>
    `api/v1/post/create/prompt/${userId}/${projectId}/${folderId}`,

  savePrompt: (userId, projectId, folderId, promptId, version) =>
    `api/v1/post/prompts/${userId}/${projectId}/${folderId}/${promptId}/${version}`,

  fetchPlatforms: () => `api/v1/fetch/ai/platforms`,
  fetchModels: (platform) => `api/v1/ai-platform/models/${platform}`,
}

export const userSettings = {
  getUserKeys: (userId) => `api/v1/get/all/config/${userId}`,
  addUserKey: (userId) => `api/v1/post/new/config/${userId}`,
  updateUserKeys: (userId) => `api/v1/update/selected/config/${userId}`,
  deleteUserKeys: (userId) => `api/v1/delete/selected/config/${userId}`,
}
export const playground = {
  geminiChat: (userId) => `api/v1/gemini/chat/${userId}`,
  openaiChat: (userId) => `api/v1/openai/chat/${userId}`,
  claudeChat: (userId) => `api/v1/claude/chat/${userId}`,

  publishPrompt: (userId) => `api/v1/post/prompts/${userId}`,
  getPromptDetails: (userId, projectId, folderId, promptId) =>
    `api/v1/get/prompts/${userId}/${projectId}/${folderId}/${promptId}`,

  getPromptVersions: (userId, projectId, folderId, prompt_id) =>
    `api/v1/get/versions/${userId}/${projectId}/${folderId}/${prompt_id}`,

  // for new api
  updateIsPublished: (userId, projectId, folderId, promptId, versionId) =>
    `api/v1/update/isPublished/${userId}/${projectId}/${folderId}/${promptId}/${versionId}`,

  getVersionDetails: (userId, projectId, folderId, promptId, versionId) =>
    `api/v1/fetch/prompts/${userId}/${projectId}/${folderId}/${promptId}/${versionId}`,
}

export const testPromptApi = {
  chatWithPrompt: (userId, versionId) => `api/v1/post/chat_with_prompt/${userId}/${versionId}`,
}
