import { Accordion, AccordionDetails, Box, CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  CustomAccordionSummary,
  LoadingBox,
  MainBox,
  SystemAccordion,
  SystemAccordionDetails,
  SystemMessageBox,
  SystemPromptBox,
  SystemTextField,
} from './studioChatStyles'

import Message from '../message/enhanced'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { v4 as uuidv4 } from 'uuid'

import { setSystemValue, setSystemValueCompare } from '../../redux/slice/Conversation'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../AuthContext'
import { useTranslation } from 'react-i18next'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Code,
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'

export const StudioChat = ({ component, show, captureVariables }) => {
  const Theme = useTheme()
  const messageBoxRef = useRef(null)
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const {
    messagesCompare,
    systemValueCompare,
    messages,
    systemValue,
    userValue,
    isLoading,
    isPublished,
    userValueCompare,
  } = useSelector((state) => state.conversations)
  const { t, ready } = useTranslation()

  const { selectedPrompt } = useSelector((state) => state.UserProjects)
  const { selectedModel, selectedModelCompare } = useSelector((state) => state.conversations)

  const [prevValue, setPrevValue] = useState('')

  const handleSystemChange = (event) => {
    const value = event.target.value
    let allMessage = ''
    component === 2
      ? messagesCompare.map((item) => {
          return (allMessage += item.content)
        })
      : messages.map((item) => {
          return (allMessage += item.content)
        })

    component === 2
      ? captureVariables(value + userValueCompare + allMessage, component)
      : captureVariables(value + userValue + allMessage, component)
    setPrevValue(value)
    if (component === 2) {
      dispatch(setSystemValueCompare(value))
    } else {
      dispatch(setSystemValue(value))
    }
  }

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
  }
  useEffect(() => {
    scrollToBottom()
  }, [messages, show, messagesCompare])
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
  }, [messages])

  return (
    <MainBox ref={messageBoxRef}>
      <SystemPromptBox>
        <Box>
          <SystemMessageBox>
            <SystemAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span> {ready ? t('studio.studioChat.system') : 'SYSTEM'}</span>
                </Box>
              </CustomAccordionSummary>

              <SystemAccordionDetails>
                <Stack direction="column" mt={0}>
                  <SystemTextField
                    label=""
                    variant="outlined"
                    value={component === 2 ? systemValueCompare : systemValue}
                    onChange={handleSystemChange}
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={15}
                    disabled={component === 2 ? false : isPublished}
                    InputProps={{
                      sx: {
                        paddingTop: '10px',
                        borderRadius: 2,
                        borderColor: Theme.palette.primary.main,
                        backgroundColor:
                          component === 2
                            ? 'inherit'
                            : isPublished
                              ? Theme.palette.secondary.main
                              : 'inherit',
                        fontSize: '20px',
                      },
                    }}
                  />
                  {/* <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'undo',
                          'redo',
                          '|',
                          'bold',
                          'italic',
                          'code',
                          'bulletedList',
                          'numberedList',
                        ],
                      },
                      plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo, List, Code],
                      licenseKey: '<YOUR_LICENSE_KEY>',
                      mention: {
                        // Mention configuration
                      },
                    }}
                    data={component === 2 ? systemValueCompare : systemValue}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      handleSystemChange({ target: { value: data } })
                    }}
                    disabled={component === 2 ? false : isPublished}
                  /> */}
                </Stack>
              </SystemAccordionDetails>
            </SystemAccordion>
          </SystemMessageBox>
          <Stack flex="1 1 auto">
            {isLoading ? (
              <LoadingBox>
                <CircularProgress />
              </LoadingBox>
            ) : component === 1 ? (
              messages &&
              messages?.map((msg, index) => (
                <Box key={index}>
                  <Message
                    role={msg?.role?.toUpperCase()}
                    message={msg.content}
                    userInfo={userInfo}
                    id={msg._id}
                    index={index}
                    {...(msg.model && { model: msg.model })}
                    isPublished={isPublished}
                    isFirst={selectedModel === 'claudeai' && index === 0}
                    prevMsgId={index > 0 ? messages[index - 1]._id : null}
                    nextMsgId={index < messages.length - 1 ? messages[index + 1]._id : null}
                  />
                </Box>
              ))
            ) : (
              messagesCompare &&
              messagesCompare?.map((msg, index) => (
                <Box key={index}>
                  <Message
                    role={msg?.role?.toUpperCase()}
                    message={msg.content}
                    userInfo={userInfo}
                    id={msg._id}
                    index={index}
                    compare={true}
                    {...(msg.model && { model: msg.model })}
                    isPublished={false}
                    isFirst={selectedModelCompare === 'claudeai' && index === 0}
                    prevMsgId={index > 0 ? messagesCompare[index - 1]._id : null}
                    nextMsgId={
                      index < messagesCompare.length - 1 ? messagesCompare[index + 1]._id : null
                    }
                  />
                </Box>
              ))
            )}
          </Stack>
        </Box>
      </SystemPromptBox>
    </MainBox>
  )
}
