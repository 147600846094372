import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Skeleton } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {
  CaptionTypography,
  GetStartedButton,
  HeadingTypography,
  ImageBox,
  LeftBox,
  MainBox,
  RightBox,
  TextBox,
} from './heroStyles'
import { Images } from '../../../assets/image'
import { USEISTAB } from '../../../constants/Constants'
import { setPage } from '../../../redux/slice/TopBar'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../router/Navigation'
import GoogleButton from '../googleButton'
import LoginDialog from '../../dialog/loginDialog'
import { useTheme } from '@emotion/react'

const Hero = ({ scrollToSection }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t, ready } = useTranslation()
  const [imageLoad, setImageLoad] = useState(true)
  const isTab = USEISTAB()

  const headingText = t('landingPage.hero.heroHeading')
  const lines = headingText.split('\n')

  const { isLogged } = useSelector((state) => state.common)
  const navigate = useNavigate()

  const handleClick = (page) => {
    navigate(navigationRoutes.user.workspace)
  }

  const [open, setOpen] = useState(false)

  const token = localStorage.getItem('token')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MainBox>
      <LeftBox
        sx={{
          order: { xs: 2, md: 1 },
          width: { xs: '100%', md: '50%' },
        }}
      >
        <TextBox
          sx={{
            textAlign: { xs: 'center', md: 'center' },
          }}
        >
          {!imageLoad ? (
            <>
              <HeadingTypography>
                {/* Unleash the Future of
                <br /> AI Development */}
                {lines?.map((line, index) => (
                  <div key={index}>
                    {line}
                    {index < lines.length - 1 && <br />}
                  </div>
                ))}
              </HeadingTypography>
              <CaptionTypography>
                {ready ? t('landingPage.hero.heroCaption') : ''}
              </CaptionTypography>
              {isLogged && token !== null ? (
                <GetStartedButton onClick={() => handleClick('getStartedRef')}>
                  {ready ? t('landingPage.hero.heroButton') : ''}
                  <ArrowRightAltIcon sx={{ ml: 1 }} />
                </GetStartedButton>
              ) : (
                // <GoogleButton />
                <>
                  <Button
                    onClick={handleOpen}
                    sx={{
                      backgroundColor: theme.palette.landingPage.hero.button,
                      padding: '20px 30px',
                      borderRadius: 50,
                      color: theme.palette.white,
                      marginTop: '40px',
                      zIndex: 1099,
                      cursor: 'pointer',
                      userSelect: 'none',
                      '&:hover': {
                        backgroundColor: theme.palette.white,
                        color: theme.palette.landingPage.hero.title,
                        border: `2px solid ${theme.palette.landingPage.hero.button}`,
                      },
                    }}
                  >
                    {ready ? t('landingPage.hero.heroButton') : ''}
                    <ArrowRightAltIcon sx={{ ml: 1 }} />
                  </Button>
                  <LoginDialog open={open} onClose={handleClose} />
                </>
              )}
            </>
          ) : (
            <>
              <Box>
                <Skeleton variant="text" sx={{ width: isTab ? '60%' : '100%' }} animation="wave">
                  <HeadingTypography>
                    {t('landingPage.hero.heroCaption')}
                    {/* Unleash the Future of
                    <br /> AI Development */}
                  </HeadingTypography>
                </Skeleton>
                <Skeleton variant="text" sx={{ width: isTab ? '50%' : '100%' }} animation="wave">
                  <CaptionTypography>
                    Harness the Power of Large Language Models for Your Applications
                  </CaptionTypography>
                </Skeleton>
              </Box>
            </>
          )}
        </TextBox>
      </LeftBox>
      <RightBox
        sx={{
          order: { xs: 1, md: 2 },
          width: { xs: '100%', md: '50%' },
        }}
      >
        {imageLoad ? (
          <ImageBox>
            <Skeleton variant="rectangular" animation="wave">
              <img
                src={Images.heroImage}
                alt="Prompt Studio Hero Page"
                style={{ width: '100%', height: 'auto' }}
                onLoad={() => setImageLoad(false)}
              />
            </Skeleton>
          </ImageBox>
        ) : (
          <img
            src={Images.heroImage}
            alt="Prompt Studio Hero Page"
            style={{ width: '100%', height: 'auto', userSelect: 'none' }}
            draggable="false"
            onLoad={() => setImageLoad(false)}
          />
        )}
      </RightBox>
    </MainBox>
  )
}

export default Hero
