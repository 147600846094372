import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { navigationRoutes } from './Navigation'

const ProtectedRoute = () => {
  const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
  let isAuthenticated
  const encodedData = localStorage.getItem(STORAGE.TOKEN)
  if (encodedData) {
    isAuthenticated = atob(encodedData)
  } else {
    isAuthenticated = null
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={navigationRoutes.main.landingPage} />
}

export default ProtectedRoute
