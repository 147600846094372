export const getStoredData = () => {
  const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE || '{}')

  const encodedUserInfo = localStorage.getItem(STORAGE.USER_INFO)
  const encodedAccessToken = localStorage.getItem(STORAGE.TOKEN)
  const encodedRefreshToken = localStorage.getItem(STORAGE.REFRESH)

  let user = null
  let accessToken = null
  let refreshToken = null

  try {
    if (encodedUserInfo) {
      user = atob(encodedUserInfo)
    }

    if (encodedAccessToken) {
      accessToken = atob(encodedAccessToken)
    }

    if (encodedRefreshToken) {
      refreshToken = atob(encodedRefreshToken)
    }
  } catch (error) {
    console.error('Error decoding or parsing stored data:', error)
  }

  return { user, accessToken, refreshToken }
}
