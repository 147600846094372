// BillingUsage.js
import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BillingUsage = () => {
  const theme = useTheme();

  return (
    <Box padding={3}>
      <Typography variant="h4" style={{ color: theme.palette.primary.main, marginBottom: '1rem' }}>
        Billing and Usage
      </Typography>
      <Paper elevation={3} style={{ padding: '1rem', backgroundColor: theme.palette.secondary.main }}>
        <Typography variant="h6" style={{ color: theme.palette.secondary.text }}>
          Current Plan: Premium
        </Typography>
        <Typography variant="body1" style={{ color: theme.palette.secondary.text }}>
          Usage this month: 75%
        </Typography>
        <Typography variant="body1" style={{ color: theme.palette.secondary.text }}>
          Billing Cycle: Monthly
        </Typography>
      </Paper>
    </Box>
  );
};

export default BillingUsage;
