import React from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setDialogOpen, setIsNewUser } from '../../../redux/slice/common'
import CommonStyles from '../../../assets/styles/CommonStyles'
import { USEISMOBILE } from '../../../constants/Constants'
import { useTranslation } from 'react-i18next'
import { StyledBox, StyledDialog, StyledGrid } from './welcomeDialogStyles'

const WelcomeDialog = () => {
  const { isNewUser, dialogOpen } = useSelector((state) => state.common)
  const dispatch = useDispatch()
  const isMobile = USEISMOBILE()

  const handleOkay = () => {
    dispatch(setDialogOpen(false))
    dispatch(setIsNewUser(false))
  }
  const { ready, t } = useTranslation()
  return (
    <StyledDialog
      open={dialogOpen}
      isMobile={isMobile}
      maxWidth="lg"
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Grid container spacing={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                {isNewUser ? (
                  <CommonStyles.StatusCode200Image />
                ) : (
                  <CommonStyles.StatusCode201Image />
                )}
              </Box>
            </Grid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledBox>
                <DialogTitle id="dialog-title">
                  <CommonStyles.TitleField>
                    {isNewUser
                      ? ready
                        ? t('studio.dialog.welcome.welcomeTo.heading')
                        : ''
                      : ready
                        ? t('studio.dialog.welcome.welcomeBack.heading')
                        : ''}
                  </CommonStyles.TitleField>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="dialog-description">
                    <CommonStyles.TitleContent>
                      {isNewUser
                        ? ready
                          ? t('studio.dialog.welcome.welcomeTo.caption')
                          : ''
                        : ready
                          ? t('studio.dialog.welcome.welcomeBack.caption')
                          : ''}
                    </CommonStyles.TitleContent>
                  </DialogContentText>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <CommonStyles.PrimaryButton
                      onClick={handleOkay}
                      color="primary"
                      style={{ width: '40%' }}
                    >
                      {ready ? t('studio.dialog.welcome.welcomeTo.button') : ''}
                    </CommonStyles.PrimaryButton>
                  </DialogActions>
                </DialogContent>
              </StyledBox>
            </StyledGrid>
          </Grid>
        }
      </Grid>
    </StyledDialog>
  )
}

export default WelcomeDialog
