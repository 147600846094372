export const navigationRoutes = {
  main: {
    landingPage: '/',
  },
  user: {
    workspace: '/user/workspace',
    settings: '/user/settings',
  },
  underMaintenance: '/underMaintenance',
}

export const navigateToUrl = (url) => {
  window.location.href = url
}

export const navigateToNewTab = (url) => {
  window.open(url, '_blank')
}
