import React, { useState } from 'react'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { USEISMOBILE } from '../../constants/Constants'
import { navigationRoutes } from '../../router/Navigation'
import { useNavigate } from 'react-router-dom'
import CommonStyles from '../../assets/styles/CommonStyles'
import {
  ColorText,
  StyledBox,
  StyledButton,
  StyledContent,
  StyledTypography,
} from './notFoundStyles'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
  const isMobile = USEISMOBILE()
  const navigate = useNavigate()
  const { ready, t } = useTranslation()

  const [isImageLoaded, setIsImageLoaded] = useState(true)

  const handleImage = () => {
    setIsImageLoaded(false)
  }

  return (
    <StyledBox isMobile={isMobile}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          {isImageLoaded ? (
            <Skeleton variant="rectangular" height={600} sx={{ mt: 20 }}>
              <CommonStyles.NotFoundImage onLoad={handleImage} />
            </Skeleton>
          ) : (
            <Box sx={{ width: '97%' }}>
              <CommonStyles.NotFoundImage onLoad={handleImage} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {isImageLoaded ? (
            <>
              <Skeleton variant="text" width={'40%'}></Skeleton>
              <Skeleton variant="text" width={'60%'}></Skeleton>
              <Skeleton variant="text" width={'50%'}></Skeleton>
              <Skeleton variant="text" width={'20%'}></Skeleton>
            </>
          ) : (
            <>
              <StyledTypography isMobile={isMobile}>
                {ready ? t('notFound.heading') : ''}
              </StyledTypography>
              <StyledTypography isMobile={isMobile}>
                <ColorText>{ready ? t('notFound.caption1') : ''}</ColorText>
                {ready ? t('notFound.caption2') : ''}
              </StyledTypography>
              <StyledContent variant="h6">
                {ready ? t('notFound.content1') : ''} <br />
                {ready ? t('notFound.content2') : ''}
              </StyledContent>
              <StyledButton
                variant="contained"
                onClick={() => navigate(navigationRoutes.main.landingPage)}
                sx={{ mb: isMobile && 5 }}
              >
                {ready ? t('notFound.button') : ''}
              </StyledButton>
            </>
          )}
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default PageNotFound
