import { Container } from '@mui/material'
import React from 'react'
import {
  CompanyNameBox,
  CompanyNameTYpography,
  CopyrightBox,
  CopyrightTypography,
  MainStack,
} from './footerStyles'

const Footer = () => {
  return (
    <MainStack>
      <Container>
        <CompanyNameBox style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CompanyNameTYpography variant="h3">PromptStudio.Dev</CompanyNameTYpography>
        </CompanyNameBox>
        <CopyrightBox>
          <CopyrightTypography>© 2024 - promptstudio.dev , All Right Reserved</CopyrightTypography>
        </CopyrightBox>
      </Container>
    </MainStack>
  )
}

export default Footer
