import Badge from '@mui/material/Badge'
import {
  Accordion,
  AccordionSummary,
  Box,
  DialogTitle,
  IconButton,
  styled,
  DialogContent,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  // marginRight: '8px',
}))
const StyledDialogTitle = styled(DialogTitle)(({ theme, isMobile, isTab }) => ({
  textAlign: 'center',
  width: '100%',
  padding: '16px',
  paddingBottom: '0px',
  fontSize: isMobile ? '24px' : isTab ? '30px' : '36px',
  userSelect: 'none',
}))

const VariableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'flex-start',
}))

const VariableNameBox = styled(Box)(({ theme }) => ({
  width: '150px',
  color: theme.palette.text.primary,
  display: 'flex',
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '2px',
  borderColor: theme.palette.primary.main,
  marginTop: '50px',
  padding: '6px',
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  // backgroundColor: theme.palette.secondary.main,
  borderRadius: '8px',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '16px',
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    fontSize: '1rem',
    minWidth: '24px',
    height: '24px',
    borderRadius: '12px',
  },
}))

export {
  StyledIconButton,
  StyledDialogTitle,
  VariableBox,
  VariableNameBox,
  StyledAccordion,
  StyledAccordionSummary,
  StyledCloseIcon,
  StyledDialogContent,
  StyledBadge,
}
