import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Slide from '@mui/material/Slide'
import TopBar from '../../components/landingPage/topBar'
import Footer from '../../components/landingPage/footer'
import PromptStudioLanding from '../../components/landingPage'
import { scroller } from 'react-scroll'
import { useTheme } from '@mui/material'

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

const LandingLayout = (props) => {
  const heroRef = useRef(null)
  const featureRef = useRef(null)
  const cardRef = useRef(null)
  const aboutRef = useRef(null)
  const contactRef = useRef(null)
  const getStartedRef = useRef(null)

  const theme = useTheme()

  const scrollToSection = (refName) => {
    scroller.scrollTo(refName, {
      duration: 'auto',
      delay: 0,
      smooth: true,
      offset: -70,
    })
  }

  useEffect(() => {
    scrollToSection('hero')
  }, [])

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar sx={{ backgroundColor: theme.palette.topBar, boxShadow: 0 }}>
          <Toolbar>
            <TopBar
              heroRef={heroRef}
              featureRef={featureRef}
              cardRef={cardRef}
              aboutRef={aboutRef}
              getStartedRef={getStartedRef}
              contactRef={contactRef}
              scrollToSection={scrollToSection}
            />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Box>
        <Container maxWidth={false} disableGutters sx={{ padding: 0, margin: 0 }}>
          <PromptStudioLanding
            heroRef={heroRef}
            featureRef={featureRef}
            cardRef={cardRef}
            getStartedRef={getStartedRef}
            aboutRef={aboutRef}
            contactRef={contactRef}
            scrollToSection={scrollToSection}
          />
        </Container>
      </Box>
      <Footer />
    </>
  )
}

export default LandingLayout
