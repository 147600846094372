import { createSlice } from '@reduxjs/toolkit'
import { signIn } from '../action/AuthAction'

const initialStates = {
  isLoading: false,
  data: null,
  error: null,
}

const auth = createSlice({
  name: 'auth',
  initialState: initialStates,
  extraReducers: (builder) => {
    // SignIn
    builder.addCase(signIn.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default auth
