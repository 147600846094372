import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: 'home',
}

const TopBarSlice = createSlice({
  name: 'topBar',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
  },
})

export const { setPage } = TopBarSlice.actions

export default TopBarSlice.reducer
