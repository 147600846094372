import React from 'react'
import { Box } from '@mui/material'
import './loadingIndicator.css'

const LoadingIndicator = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2, mb: 2 }}>
      <div className="loading-indicator">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </Box>
  )
}

export default LoadingIndicator
