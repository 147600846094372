import {
  Box,
  Drawer,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState, useEffect, useRef } from 'react'
import Message from '../testPromptMessage'
import { useDispatch, useSelector } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings'
import { USEISMOBILE } from '../../../constants/Constants'
import { MemorySettingsPopover, SettingsButton } from './testPromptDrawerStyles'
import { chatWithPrompt } from '../../../redux/action/TestPromptActions'
import { useAuth } from '../../../AuthContext'
import { toast } from 'react-toastify'
import { TestVariables } from '../testVariables'
import Swal from 'sweetalert2'
import { useTheme } from '@emotion/react'
import './style.css'
import LoadingIndicator from './LoadingIndicator'

const TestPromptDrawer = ({ open, handleClose }) => {
  const [memoryType, setMemoryType] = useState('windowMemory')
  const [windowSize, setWindowSize] = useState(2)
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [showMemorySettings, setShowMemorySettings] = useState(false)
  const [chatWithPromptLoading, setChatWithPromptLoading] = useState(false)
  const [variableOpen, setVariableOpen] = useState(false)
  const [chatSessionId, setChatSessionId] = useState('')

  const { selectedPrompt, promptVersions, selectedPromptVersion } = useSelector(
    (state) => state.UserProjects,
  )
  const Theme = useTheme()

  const messagesEndRef = useRef(null)
  const isMobile = USEISMOBILE()

  const { userInfo } = useAuth()
  const dispatch = useDispatch()

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const { variables, selectedModel } = useSelector((state) => state.conversations)

  const [lclVariables, setLclVariables] = useState(variables)

  useEffect(() => {
    if (memoryType !== 'windowMemory') {
      setWindowSize('5')
    }
  }, [memoryType])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (chatSessionId === '') {
      setMessages([])
    }
  }, [chatSessionId])

  useEffect(() => {
    if (selectedModel !== 'claudeai') {
      setMemoryType('windowMemory')
    } else {
      setMemoryType('fullMemory')
    }
  }, [selectedModel])
  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }])
      chatWithPromptFn(chatData)
      setInput('')
    }
  }

  const requestPayloadFormat = (variables) => {
    return variables.reduce((acc, variable) => {
      acc[variable.key] = variable.value
      return acc
    }, {})
  }

  const chatData = {
    memory_type: memoryType,
    window_size: windowSize,
    user_message: input,
    session_id: chatSessionId,
    env: 'test',
    request_from: 'prompt studio',
    variables: requestPayloadFormat(lclVariables.length > 0 ? lclVariables : variables),
  }

  const chatWithPromptFn = (chatData) => {
    setChatWithPromptLoading(true)
    try {
      dispatch(
        chatWithPrompt({
          userId: userInfo?.userId,
          versionId: selectedPromptVersion,
          chatData,
          meta: {
            onSuccess: (data) => {
              setMessages((prev) => [
                ...prev,
                { text: data?.data?.result?.response, sender: 'bot' },
              ])
              setChatSessionId(data?.data?.result?.session_id)
              setChatWithPromptLoading(false)
            },
            onError: (error) => {
              setChatWithPromptLoading(false)
              toast.error(error.data.message)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const popoverRef = useRef(null)

  const handleVariableToggle = () => {
    setVariableOpen(!variableOpen)
  }

  const handleInputChange = (e) => {
    const sanitizedInput = e.target.value.replace(/[{}]/g, '')
    setInput(sanitizedInput)
  }

  const handleCloseConfirm = () => {
    setShowMemorySettings(false)
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will reset the conversation',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reset it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleClose()
        setChatSessionId('')
        if (selectedModel !== 'claudeai') {
          setMemoryType('windowMemory')
        } else {
          setMemoryType('fullMemory')
        }
        setLclVariables([])
        setMessages([])
      }
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowMemorySettings(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  return (
    <Drawer
      anchor="right"
      open={open}
      // onClose={handleClose}
      sx={{ '& .MuiPaper-root': { width: isMobile ? '100%' : '35vw' } }}
    >
      <Stack sx={{ height: '100%', p: 2, position: 'relative' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{selectedPrompt.promptName}</Typography>
          <Stack direction="row" spacing={1}>
            <TestVariables
              handleToggle={handleVariableToggle}
              variableOpen={variableOpen}
              lclVariables={lclVariables}
              setLclVariablesInital={setLclVariables}
            />
            <SettingsButton onClick={() => setShowMemorySettings(!showMemorySettings)}>
              <SettingsIcon />
            </SettingsButton>
            <IconButton onClick={handleCloseConfirm} edge="end" aria-label="close">
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
          {showMemorySettings && (
            <MemorySettingsPopover ref={popoverRef}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                {/* <Typography variant="subtitle1">Memory Settings</Typography>
                <IconButton onClick={() => setShowMemorySettings(false)} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton> */}
              </Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Memory Type</FormLabel>
                <RadioGroup
                  aria-label="memory-type"
                  name="memory-type"
                  value={memoryType}
                  onChange={(e) => setMemoryType(e.target.value)}
                >
                  <FormControlLabel value="fullMemory" control={<Radio />} label="Full memory" />
                  {selectedModel !== 'claudeai' && (
                    <FormControlLabel
                      value="windowMemory"
                      control={<Radio />}
                      label="Window memory"
                    />
                  )}
                  {memoryType === 'windowMemory' && (
                    <TextField
                      type="number"
                      value={windowSize}
                      onChange={(e) => setWindowSize(e.target.value)}
                      InputProps={{ inputProps: { min: 1 } }}
                      label="Window Size"
                      size="small"
                      sx={{ mt: 2, width: '165px' }}
                    />
                  )}
                  {/* {selectedModel !== 'gemini' && ( */}
                    <FormControlLabel value="summarizedMemory" control={<Radio />} label="Summary" />
                  {/* )} */}
                </RadioGroup>
              </FormControl>
            </MemorySettingsPopover>
          )}
        </Stack>
        <Box
          sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: '10px' }}
          ref={(el) => {
            if (el) {
              el.scrollTop = el.scrollHeight
            }
          }}
        >
          {messages.map((message, index) => (
            <Message key={index} {...message} />
          ))}
          {chatWithPromptLoading && <LoadingIndicator />}
          <div ref={messagesEndRef} />
        </Box>
        <Stack direction="row" spacing={1}>
          <TextField
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '8px',
                height: 40,
              },
            }}
            fullWidth
            value={input}
            onChange={(e) => handleInputChange(e)}
            placeholder="Type your message..."
            onKeyPress={(e) => !chatWithPromptLoading && e.key === 'Enter' && handleSend()}
          />
          <Button
            variant="contained"
            onClick={handleSend}
            sx={{ borderRadius: '8px' }}
            disabled={chatWithPromptLoading}
          >
            Send
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default TestPromptDrawer
