import React from 'react'
import { Box } from '@mui/material'
import Hero from './hero'
import Card from './card'
import About from './about'
import Feature from './feature'
import ContactUs from './contactus'
import GetStarted from './getStarted'

const PromptStudioLanding = ({
  heroRef,
  featureRef,
  cardRef,
  getStartedRef,
  aboutRef,
  contactRef,
  scrollToSection,
}) => (
  <Box position={'relative'}>
    <section id="hero" ref={heroRef}>
      <Hero scrollToSection={scrollToSection} />
    </section>
    <section id="feature" ref={featureRef}>
      <Feature />
    </section>
    <section id="card" ref={cardRef}>
      <Card />
    </section>
    {/* <section id="getStarted" ref={getStartedRef}>
      <GetStarted />
    </section> */}
    <section id="about" ref={aboutRef}>
      <About />
    </section>
    <section id="contact" ref={contactRef}>
      <ContactUs />
    </section>
  </Box>
)

export default PromptStudioLanding
