import { useState } from 'react'
import CardContent from '@mui/material/CardContent'
import { Box, Grid, Skeleton } from '@mui/material'
import { KEY_FEATURES, USEISMOBILE } from '../../../constants/Constants'
import { ImageBox, MainCard, MainGrid, TypographyContent, TypographyTitle } from './Cardstyle'
import { useTranslation } from 'react-i18next'

const Card = () => {
  const isMobile = USEISMOBILE()
  const { t, ready } = useTranslation()

  const features = KEY_FEATURES(t, ready)

  const [imageLoaded, setImageLoaded] = useState(features.map(() => false))

  const handleImageLoad = (index) => {
    setImageLoaded((prevState) => {
      const newState = [...prevState]
      newState[index] = true
      return newState
    })
  }

  return (
    <MainGrid container spacing={2}>
      {features?.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <MainCard>
            <ImageBox>
              {!imageLoaded[index] && (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={250} />
              )}
              <img
                src={item.image}
                style={{ width: '100%', display: imageLoaded[index] ? 'block' : 'none' }}
                onLoad={() => handleImageLoad(index)}
                alt={`feature-${index}`}
                draggable={false}
              />
            </ImageBox>

            <CardContent>
              <TypographyTitle gutterBottom variant="h4">
                {imageLoaded[index] ? (
                  item.title
                ) : (
                  <Box paddingX={10}>
                    <Skeleton variant="text" animation="wave" width={isMobile ? 250 : 270} />
                  </Box>
                )}
              </TypographyTitle>
              <TypographyContent variant={isMobile ? 'body1' : 'h6'} component="span">
                {imageLoaded[index] ? (
                  <>{item.content}</>
                ) : (
                  <Box paddingX={10}>
                    <Skeleton variant="text" animation="wave" width="250" />
                  </Box>
                )}
              </TypographyContent>
            </CardContent>
          </MainCard>
        </Grid>
      ))}
    </MainGrid>
  )
}

export default Card
