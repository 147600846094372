import { Slider, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const StyledSlider = styled(Slider)(({ theme, disabled }) => ({
  color: disabled ? '#b0b0b0' : theme.palette.primary.light, // Gray when disabled, otherwise primary color
  height: 5,
  padding: '10px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: disabled ? '#b0b0b0' : theme.palette.primary.main, // Gray when disabled, otherwise primary color
    boxShadow: disabled ? 'none' : '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: disabled ? 'none' : '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: disabled ? 'none' : iOSBoxShadow,
      },
    },
    '&:before': {
      boxShadow: disabled
        ? 'none'
        : '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 4, // Adjust this value to move the label down or up
    backgroundColor: 'unset',
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary, // Gray when disabled, otherwise primary text color
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: disabled ? theme.palette.primary.dark : theme.palette.primary.main, // Gray when disabled, otherwise primary color
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
    backgroundColor: disabled ? theme.palette.secondary.dark : theme.palette.primary.light, // Gray when disabled, otherwise primary color
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: disabled ? '#e0e0e0' : '#d0d0d0', // Lighter gray when disabled
  },
}))

const Input = styled(TextField)({
  width: '80px',
})

export { StyledSlider, Input }
