import React from 'react'
import { Dialog, Slide } from '@mui/material'
import { StyledCloseIcon } from './singleDialogStyles'

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const SingleDialog = ({ DialogContentComponent, handleClose, open }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      {DialogContentComponent}
      <StyledCloseIcon onClick={handleClose}>Close</StyledCloseIcon>
    </Dialog>
  )
}

export default SingleDialog
