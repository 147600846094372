/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Slide,
  SvgIcon,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Icons, Images } from '../image'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import CloseIcon from '@mui/icons-material/Close'

const handleContextMenu = (event) => {
  event.preventDefault()
}

// Images and Icons

const CompanyMainLogo = () => {
  return (
    <img
      src={Images.logo}
      width="120"
      alt="PromptStudio.dev Logo"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const CompanyMainWhiteLogo = () => {
  return (
    <img
      src={Images.logoWhite}
      width="120"
      alt="PromptStudio.dev Logo"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const CompanyLogo = () => {
  return (
    <img
      src={Images.Footerlogo}
      width="200px"
      height="200px"
      alt="PromptStudio.dev Logo"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const CompanyLogoFooter = ({ width, height }) => {
  return (
    <img
      src={Images.Footerlogo}
      height={height}
      width={width}
      alt="PromptStudio.dev Logo"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}
const CompanyLogoWhite = ({ width, height }) => {
  return (
    <img
      src={Images.logoWhite}
      width="100"
      alt="PromptStudio.dev Logo"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}
const HeroImage = () => {
  return (
    <img
      src={Images.heroImage}
      alt="Prompt Studio Hero Page"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const Feature1 = () => {
  return (
    <LazyLoadImage
      src={Images.feature1}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}

const Feature2 = () => {
  return (
    <LazyLoadImage
      src={Images.feature2}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}
const Feature3 = () => {
  return (
    <LazyLoadImage
      src={Images.feature3}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}

const Feature4 = () => {
  return (
    <LazyLoadImage
      src={Images.feature4}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}
const Feature5 = () => {
  return (
    <LazyLoadImage
      src={Images.feature5}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}
const Feature6 = () => {
  return (
    <LazyLoadImage
      src={Images.feature6}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}
const Feature7 = () => {
  return (
    <LazyLoadImage
      src={Images.feature7}
      width="100%"
      alt="feature 1"
      draggable="false"
      onContextMenu={handleContextMenu}
      effect="blur"
    />
  )
}
const Aside = () => {
  return (
    <Box zIndex={-1}>
      <img
        src={Images.aside}
        width="100%"
        height="100%"
        alt="Aside"
        draggable="false"
        onContextMenu={handleContextMenu}
      />
    </Box>
  )
}
const Aside2 = () => {
  return (
    <Box zIndex={-1}>
      <img
        src={Images.aside2}
        width="100%"
        height="50%"
        alt="Aside 1"
        draggable="false"
        onContextMenu={handleContextMenu}
      />
    </Box>
  )
}
const Aside3 = () => {
  return (
    <Box zIndex={-1}>
      <img
        src={Images.aside3}
        width="100%"
        height="100%"
        alt="feature 1"
        draggable="false"
        onContextMenu={handleContextMenu}
      />
    </Box>
  )
}
const Aside4 = () => {
  return (
    <Box position={'absolute'} right={0} top={3200} zIndex={-1}>
      <img
        src={Images.aside4}
        width="100%"
        height="100%"
        alt="feature 1"
        draggable="false"
        onContextMenu={handleContextMenu}
      />
    </Box>
  )
}

const AboutImage = () => {
  return (
    <img
      src={Images.aboutImage}
      alt="AboutImage"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}
const FeatureOverview = () => {
  return (
    <img
      src={Images.featureOverview}
      width={'100%'}
      alt="Feature Overview"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const NotFoundImage = ({ onLoad }) => {
  return (
    <img
      src={Images.pageNotFound}
      width={'100%'}
      alt="Page Not Found"
      draggable="false"
      onLoad={onLoad}
      onContextMenu={handleContextMenu}
    />
  )
}

const ProfileImage = ({ url }) => {
  return (
    <img
      src={url}
      width={'50px'}
      style={{ borderRadius: '100%' }}
      alt="Profile Icon"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const StatusCode200Image = () => {
  return (
    <img
      src={Images.StatusCode200}
      width={'100%'}
      alt="Welcome Back To PromptStudio"
      draggable="false"
      height={'100%'}
      onContextMenu={handleContextMenu}
    />
  )
}

const StatusCode201Image = () => {
  return (
    <img
      src={Images.StatusCode201}
      width={'100%'}
      alt="Welcome To PromptStudio"
      draggable="false"
      height={'100%'}
      onContextMenu={handleContextMenu}
    />
  )
}

const AiKeyImage = () => {
  return (
    <img
      src={Images.AiKey}
      width={'100%'}
      alt="AI Key"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

const ComingSoon = () => {
  return (
    <img
      src={Images.ComingSoon}
      width="100%"
      height="auto"
      alt="PromptStudio Coming Soon"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

// CSS and Stylings

const BackgroundArea = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  backgroundImage: `url(${Images.loginBG})`,
  backgroundSize: 'cover',
  // backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overflow: 'auto',
}))

const ContainerArea = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  maxWidth: '90%',
}))

const PaperArea = styled(Paper)(() => ({
  padding: '30px 30px 10px 30px',
  borderRadius: '8px',
  backgroundColor: 'white',
  width: { xs: '100%', sm: '80%', md: '50%', lg: '40%', xl: '30%' },
}))

const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '32px',
  textAlign: 'center',
  marginTop: '10px',
  color: theme.palette.text,
  userSelect: 'none',
}))

const TypographyField = styled(Typography)(({ theme }) => ({
  fontWeight: '550',
  textAlign: 'left',
  fontSize: '12px',
  color: theme.palette.typography,
  userSelect: 'none',
}))

const VariableSizeTextField = styled(TextField)(({ isTab, theme }) => ({
  '& .MuiInputBase-root': {
    fontWeight: '400',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    gap: '5px',
    fontSize: '15px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '5px',
      borderWidth: '1px',
      transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
    },
    '&.Mui-focused fieldset': {
      boxShadow: `0 0 2px `,
    },
  },
  width: isTab ? '100%' : '100%',
  marginTop: '5px',
}))

const ImageBox = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: '100%',
  objectFit: 'contain',
  padding: '16px',
  userSelect: 'none',
}))

const RightGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'start',
  padding: '40px',
}))

const LeftGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// Styling Components

const LoadingButtonBar = ({ isMobile }) => {
  return (
    <CircularProgress
      color="inherit"
      size={isMobile ? 15 : 20}
      sx={{ transition: 'opacity 0.3s ease-in-out' }}
    />
  )
}

const AnimatedFormHelperText = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.2, ease: 'easeInOut' }}
  >
    {children}
  </motion.div>
)

const CloseIconButton = ({ right = 8, top = 8, ...props }) => {
  return (
    <IconButton
      style={{
        position: 'absolute',
        right: right,
        top: top,
      }}
      color="primary"
      {...props}
    >
      <CloseIcon />
    </IconButton>
  )
}

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TitleField = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  fontSize: '250%',
  [theme.breakpoints.only('xs')]: {
    fontSize: '110%',
  },
  [theme.breakpoints.only('sm')]: {
    fontSize: '190%',
  },
  [theme.breakpoints.only('md')]: {
    fontSize: '150%',
  },
}))

const TitleContent = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  fontSize: '110%',
  [theme.breakpoints.only('xs')]: {
    fontSize: '90%',
  },
  [theme.breakpoints.only('sm')]: {
    fontSize: '150%',
  },
  [theme.breakpoints.only('md')]: {
    fontSize: '110%',
  },
}))

const ButtonField = styled(Button)(({ theme, width }) => ({
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: theme.spacing(5),
  borderRadius: 30,
  width: width,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const ArrowUpDownIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 5l-5 5h10l-5-5zm0 14l5-5H7l5 5z" />
    </SvgIcon>
  )
}

const PrimaryButton = styled(Button)(({ theme, width, error, ismobile }) => ({
  width: width,
  height: ismobile ? '30px' : '41px',
  fontWeight: '500',
  fontSize: ismobile ? '10px' : '14px',
  lineHeight: '16.94px',
  borderRadius: '5px',
  boxShadow: 'none',
  backgroundColor: error ? theme.palette.errorStatus : theme.palette.primary.main,
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: ismobile ? '5px 10px' : '12px 29px 12px 29px',
  textTransform: 'capitalize',
  color: 'white',
  '&:disabled': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    color: 'white',
    cursor: 'not-allowed',
    pointerEvents: 'all !important',
  },
  '&:hover': {
    backgroundColor: error ? theme.palette.errorStatus : theme.palette.primary.main,
    boxShadow: 'none',
  },
}))

const SecondaryButton = styled(Button)(({ theme, width, error }) => ({
  width: width,
  height: '41px',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16.94px',
  borderRadius: '5px',
  boxShadow: 'none',
  backgroundColor: error ? theme.palette.errorStatus : theme.palette.secondary.main,
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px 29px 12px 29px',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  '&:disabled': {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.5,
    color: theme.palette.primary.main,
    cursor: 'not-allowed',
    pointerEvents: 'all !important',
  },
  '&:hover': {
    backgroundColor: error ? theme.palette.errorStatus : theme.palette.secondary.main,
    boxShadow: 'none',
  },
}))

const DangerButton = styled(Button)(({ theme, width, error }) => ({
  width: width,
  height: '41px',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16.94px',
  borderRadius: '5px',
  boxShadow: 'none',
  backgroundColor: theme.palette.redText,
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px 29px 12px 29px',
  textTransform: 'capitalize',
  color: theme.palette.white,
  '&:disabled': {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.5,
    color: theme.palette.primary.main,
    cursor: 'not-allowed',
    pointerEvents: 'all !important',
  },
  '&:hover': {
    backgroundColor: theme.palette.redText,
    boxShadow: 'none',
  },
}))

const GoogleIcon = () => {
  return (
    <img
      src={Icons.googleIcon}
      alt="Google Icon"
      draggable="false"
      onContextMenu={handleContextMenu}
    />
  )
}

export default {
  CompanyMainLogo,
  CompanyLogo,
  HeroImage,
  AboutImage,
  FeatureOverview,
  ComingSoon,
  BackgroundArea,
  ContainerArea,
  PaperArea,
  HeadingTypography,
  TypographyField,
  VariableSizeTextField,
  PrimaryButton,
  SecondaryButton,
  CloseIconButton,
  RightGrid,
  LeftGrid,
  LoadingButtonBar,
  AnimatedFormHelperText,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature6,
  Feature7,
  CompanyLogoFooter,
  ImageBox,
  Aside,
  Aside2,
  Aside3,
  Aside4,
  Transition,
  CompanyLogoWhite,
  NotFoundImage,
  ProfileImage,
  StatusCode200Image,
  StatusCode201Image,
  AiKeyImage,
  TitleField,
  TitleContent,
  ButtonField,
  ArrowUpDownIcon,
  CompanyMainWhiteLogo,
  DangerButton,
  GoogleIcon,
}
