import { Box, styled } from '@mui/material'

const MainBox = styled(Box)(() => ({
  display: 'flex',
}))

const StyledBox = styled(Box)(() => ({
  flexGrow: 1,
  minHeight: 'calc(100vh - 60px)',
  marginTop: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflowX: 'auto',
}))

export { MainBox, StyledBox }
