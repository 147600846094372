import { styled, Box } from '@mui/material'

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(5, 6), // Equivalent to py: 5, px: 6
  borderRadius: 16,
}))

export { ModalBox }
