import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import ErrorBoundary from './loggers/ErrorBoundary'
import { AuthProvider } from './AuthContext'
import { I18nextProvider } from 'react-i18next'
import i18n from './config/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </ErrorBoundary>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
)

// Register service worker
serviceWorkerRegistration.register()

// Measure performance
reportWebVitals()
