import * as React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import PermMediaIcon from '@mui/icons-material/PermMedia'
import FolderIcon from '@mui/icons-material/Folder'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

import { useSelector } from 'react-redux'
import { BreadcrumbBox, BreadCrumbText } from './BreadCrumbStyles'

function handleClick(event) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

const BreadCrumb = () => {
  const { selectedProject, selectedPrompt, projectsData } = useSelector(
    (state) => state.UserProjects,
  )

  return (
    projectsData?.folders?.length !== 0 && (
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <BreadcrumbBox>
            <PermMediaIcon margin fontSize="inherit" />
            <BreadCrumbText underline="hover" color="inherit" href="/">
              {selectedProject ? selectedProject : ''}
            </BreadCrumbText>
          </BreadcrumbBox>

          {selectedPrompt?.promptFolderName && (
            <BreadcrumbBox>
              <FolderIcon fontSize="inherit" />
              <BreadCrumbText color="inherit">{selectedPrompt.promptFolderName}</BreadCrumbText>
            </BreadcrumbBox>
          )}

          {selectedPrompt?.promptName && (
            <BreadcrumbBox>
              <TextSnippetIcon fontSize="inherit" />
              <BreadCrumbText color="text.primary">{selectedPrompt.promptName}</BreadCrumbText>
            </BreadcrumbBox>
          )}
        </Breadcrumbs>
      </div>
    )
  )
}

export default BreadCrumb
