import React from 'react'
import ProtectedRoute from './ProtectedRoute'
import SettingsPage from '../pages/settings'
import { navigationRoutes } from './Navigation'
import { motion } from 'framer-motion'
import { PAGETRANSITION } from '../constants/Constants'
import StudioLayout from '../layout/studioLayout'

const UserRouteConfig = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: navigationRoutes.user.workspace,
        element: (
          <motion.div {...PAGETRANSITION}>
            <StudioLayout />
          </motion.div>
        ),
        title: 'Workspace',
      },
      {
        path: navigationRoutes.user.settings,
        element: (
          <motion.div {...PAGETRANSITION}>
            <SettingsPage />
          </motion.div>
        ),
        title: 'Settings',
      },
    ],
  },
]

export default UserRouteConfig
