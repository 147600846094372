import { Box, Divider, Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import CommonStyles from '../../../assets/styles/CommonStyles'
import { CaptionTypography, RedTypography } from './featureStyles'
import { USEISMOBILE } from '../../../constants/Constants'
import { Images } from '../../../assets/image'
import { useTranslation } from 'react-i18next'

const Feature = () => {
  const isMobile = USEISMOBILE()
  const [isLoaded, setIsLoaded] = useState(false)

  const { t } = useTranslation()
  return (
    <Grid container direction="row" spacing={0}>
      <CommonStyles.LeftGrid item xs={12} sm={12} md={6}>
        {!isLoaded && (
          <Box marginTop={4} marginLeft={6}>
            <Skeleton variant="rectangular" animation="wave" width={500} height={300} />
          </Box>
        )}
        <CommonStyles.ImageBox
          component="img"
          src={Images.featureOverview}
          alt="Prompt Studio Hero Page"
          onLoad={() => setIsLoaded(true)}
          style={{ display: isLoaded ? 'block' : 'none' }}
          draggable="false"
        />
      </CommonStyles.LeftGrid>
      <CommonStyles.RightGrid item xs={12} sm={12} md={6}>
        {!isLoaded ? (
          <>
            <Skeleton variant="text" width="60%" />
            <Divider />
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="70%" />
          </>
        ) : (
          <>
            <RedTypography>{t('landingPage.feature.featureHeading')}</RedTypography>
            <Divider />
            <CaptionTypography isMobile={isMobile}>
              {t('landingPage.feature.featureCaption')}
            </CaptionTypography>
          </>
        )}
      </CommonStyles.RightGrid>
    </Grid>
  )
}

export default Feature
