import { Box, Dialog, Grid, styled } from '@mui/material'

const StyledDialog = styled(Dialog)(({ theme, isMobile }) => ({
  '& .MuiDialog-paper': {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? '16px' : '56px',
  },
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  overflowWrap: 'break-word',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  userSelect: 'none',
}))

const StyledBox = styled(Box)({
  textAlign: 'center',
  width: '100%',
})

export { StyledDialog, StyledGrid, StyledBox }
