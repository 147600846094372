import { useEffect, useState, useRef, useCallback } from 'react'
import { toast } from 'react-toastify'
import { setUserValue } from '../../redux/slice/Conversation'
import MicIcon from '@mui/icons-material/Mic'
import StopIcon from '@mui/icons-material/Stop'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'
import axiosInstance from '../../util/axios'

const SpeechToText = ({
  role,
  setAssistantValue,
  setIsMicProcessing,
  isMicProcessing,
  runLoading,
}) => {
  const [isRecording, setIsRecording] = useState(false)
  const [audioLevel, setAudioLevel] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const mediaRecorderRef = useRef(null)
  const audioContextRef = useRef(null)
  const analyserRef = useRef(null)
  const audioChunksRef = useRef([])
  const animationFrameRef = useRef(null)
  const streamRef = useRef(null)
  const dispatch = useDispatch()
  const theme = useTheme()

  useEffect(() => {
    return () => {
      stopRecording(false)
    }
  }, [])

  const startRecording = async () => {
    if (isProcessing) return

    try {
      audioChunksRef.current = []

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      streamRef.current = stream
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' })

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()
      analyserRef.current = audioContextRef.current.createAnalyser()
      analyserRef.current.fftSize = 256
      const source = audioContextRef.current.createMediaStreamSource(stream)
      source.connect(analyserRef.current)

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data)
        }
      }

      mediaRecorderRef.current.start()
      setIsRecording(true)
      setIsMicProcessing(true)
      visualizeAudio()
    } catch (error) {
      console.error('Error accessing microphone:', error)
      toast.error('Error accessing microphone. Please check your permissions.')
    }
  }

  const stopRecording = useCallback(
    (intentional = true) => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop()
        mediaRecorderRef.current.onstop = () => {
          if (intentional && audioChunksRef.current.length > 0) {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm;codecs=opus' })
            console.log('Recorded audio data size:', audioBlob.size, 'bytes')
            setIsProcessing(true)
            processAudio(audioBlob)
          }
          audioChunksRef.current = []
        }
      }

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop())
        streamRef.current = null
      }

      if (audioContextRef.current) {
        audioContextRef.current.close().catch(console.error)
        audioContextRef.current = null
      }

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
        animationFrameRef.current = null
      }

      setIsRecording(false)
      setAudioLevel(0)
    },
    [dispatch, role, setAssistantValue, setIsMicProcessing],
  )

  const processAudio = async (audioBlob) => {
    try {
      const formData = new FormData()
      formData.append('audio_file', audioBlob)
      const action = await axiosInstance.post(
        `${process.env.REACT_APP_API_BASE_URL}speech_to_text`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      console.log('Speech to text action result:', action)

      if (action.status === 200 && action.data.transcript) {
        const transcript = action.data.transcript
        console.log('Transcript received:', transcript)
        if (role === 'user') {
          dispatch(setUserValue(transcript))
        } else {
          setAssistantValue(transcript)
        }
      } else if (action.error) {
        console.error('Error in speech to text:', action.error)
        toast.error(action.error.message || 'Error processing audio')
      } else {
        console.warn('No transcript in payload')
        toast.warn('No transcript found')
      }
    } catch (error) {
      console.error('Error in speech to text:', error)
      toast.error('Error processing audio. Please try again.')
    } finally {
      setIsProcessing(false)
      setIsMicProcessing(false)
    }
  }

  const visualizeAudio = () => {
    if (!analyserRef.current) return

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount)
    const updateAudioLevel = () => {
      analyserRef.current.getByteFrequencyData(dataArray)
      const average = dataArray.reduce((a, b) => a + b) / dataArray.length
      const normalizedLevel = Math.pow(average / 255, 0.7) // Reduced sensitivity
      setAudioLevel(normalizedLevel)
      animationFrameRef.current = requestAnimationFrame(updateAudioLevel)
    }
    updateAudioLevel()
  }

  const handleSpeechToggle = () => {
    if (isRecording) {
      stopRecording(true)
    } else {
      startRecording()
    }
  }

  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <div
        onClick={handleSpeechToggle}
        style={{
          cursor: isProcessing || runLoading ? 'not-allowed' : 'pointer',
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          backgroundColor: isRecording ? '#dc3545' : theme.palette.primary.main,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.1s ease-in-out',
          transform: isRecording ? `scale(${1 + audioLevel * 1.5})` : 'scale(1)',
          opacity: isProcessing || runLoading ? 0.5 : 1,
        }}
      >
        {!isRecording && <MicIcon style={{ color: 'white', fontSize: '1.5rem' }} />}
        {isRecording && <StopIcon style={{ color: 'white', fontSize: '1.5rem' }} />}
      </div>
    </div>
  )
}

export default SpeechToText
