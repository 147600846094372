import { Menu, styled } from '@mui/material'
import { Button } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto'

const ThemeButton = styled(Button)(({ theme }) => ({
  border: 'none',
  color: theme.palette.textColor,
  '&:hover': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
}))

const StyledLightModeIcon = styled(LightModeIcon)(({ theme }) => ({
  width: '1.8rem',
  height: '1.8rem',
  color: theme.palette.textColor,
  '&:hover': {
    color: theme.palette.redText,
  },
}))

const StyledDarkModeIcon = styled(DarkModeIcon)(({ theme }) => ({
  width: '1.8rem',
  height: '1.8rem',
  color: theme.palette.textColor,
  '&:hover': {
    color: theme.palette.redText,
  },
}))

const StyledBrightnessAutoIcon = styled(BrightnessAutoIcon)(({ theme }) => ({
  width: '1.8rem',
  height: '1.8rem',
  color: theme.palette.textColor,
  '&:hover': {
    color: theme.palette.redText,
  },
}))

const StyledMenu = styled(Menu)(() => ({
  '.css-6hp17o-MuiList-root-MuiMenu-list': {
    paddingBottom: 0,
    paddingTop: 0,
  },
}))

export {
  ThemeButton,
  StyledLightModeIcon,
  StyledDarkModeIcon,
  StyledBrightnessAutoIcon,
  StyledMenu,
}
