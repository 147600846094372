import { Box, styled } from '@mui/material'

const CenterMainbox = styled(Box)(({ theme }) => ({
  border: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '84vh',
  mt: '9%',
  flex: 1,
  overflow: 'hidden',
}))

export { CenterMainbox }
