import { alpha, createTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.sumsub-container': {
          height: '100%',
        },
        '.sumsub-container iframe': {
          minHeight: '99%',
        },
        /* width */
        '::-webkit-scrollbar': {
          width: 5,
          height: 5,
        },

        /* Track */
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
          background: '#888',
        },

        /* Handle on hover */
        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#314584',
      icon: '#ffffff',
    },
    secondary: {
      main: '#eff3ff',
      text: '#DDDEDE',
    },
    landingPage: {
      hero: {
        title: '#314584',
        describe: '#6E757A',
        button: '#FF3946',
      },
      features: {
        title: '#FF3946',
        describe: '#314584',
      },
      card: {
        title: '#314584',
        describe: '#314584',
      },
      signIn: {
        title: '#FF3946',
        describe: '#314584',
        button: '#314584',
      },
      aboutUs: {
        mainTitle: '#FF3946',
        title: '#314584',
        describe: '#FF3946',
      },
      contactUs: {
        describe: '#FF3946',
        title: alpha('#314584', 0.3),
      },
      footer: {
        bg: '#314584',
        title: '#FF3946',
        text: '#ffffff',
      },
    },
    studio: {
      topBar: '#eff3ff',
      leftBar: '#eff3ff',
      leftTitle: '#314584',
      dropdown: '#314584',
      publishButton: '#314584',
      historyButton: '#314584',
      userButton: '#314584',
      runButton: '#314584',
    },
    profile: {
      bg: '#314584',
      imgBg: '#f2f5ff',
      button: '#314584',
      cross: '#ffffff',
    },
    setting: {
      mainTitle: '#314584',
      textField: '#eff3ff',
    },
    endpoint: {
      background: '#ffffff',
      paper: 'black',
      text: 'white',
    },
    footer: '#314584',
    topBar: '#ffffff',
    topbarHover: '#FF3946',
    redText: '#FF3946',
    white: '#ffffff',
    textColor: '#314584',
    heroCaption: '#6E757A',
    drawerButton: alpha('#314584', 0.1),
    drawerButtonHover: alpha('#314584', 0.1),
    drawerButtonBright: alpha('#314584', 0.6),
    aidropdown: {
      blue: {
        50: '#F0F7FF',
        100: '#C2E0FF',
        200: '#99CCF3',
        300: '#66B2FF',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E6',
        700: '#0059B3',
        800: '#004C99',
        900: '#003A75',
      },
      grey: {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
      },
    },
    scrollBar: '#888',
    scrollBarHover: '#555',
    scrollBarBg: '#f1f1f1',
    black: '#000000',
  },
})

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.sumsub-container': {
          height: '100%',
        },
        '.sumsub-container iframe': {
          minHeight: '99%',
        },
        /* width */
        '::-webkit-scrollbar': {
          width: 5,
          height: 5,
        },

        /* Track */
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
          background: '#888',
        },

        /* Handle on hover */
        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      icon: '#615959',
    },
    secondary: {
      main: '#333436',
      text: '#B0B8C4',
    },
    landingPage: {
      hero: {
        title: '#90caf9',
        describe: '#6E757A',
        button: '#FF3946',
      },
      features: {
        title: '#FF3946',
        describe: '#90caf9',
      },
      card: {
        title: '#90caf9',
        describe: '#90caf9',
      },
      signIn: {
        title: '#FF3946',
        describe: '#90caf9',
        button: '#90caf9',
      },
      aboutUs: {
        mainTitle: '#FF3946',
        title: '#90caf9',
        describe: '#FF3946',
      },
      contactUs: {
        describe: '#FF3946',
        title: alpha('#90caf9', 0.3),
      },
      footer: {
        bg: '#333436',
        title: '#FF3946',
        text: '#ffffff',
      },
    },
    studio: {
      topBar: '#333436',
      leftBar: '#333436',
      leftTitle: '#90caf9',
      dropdown: '#90caf9',
      publishButton: '#90caf9',
      historyButton: '#90caf9',
      userButton: '#90caf9',
      runButton: '#90caf9',
    },
    profile: {
      bg: '#90caf9',
      imgBg: '#333436',
      button: '#ffffff',
      cross: '#333436',
    },
    setting: {
      mainTitle: '#90caf9',
      textField: '#333436',
    },
    endpoint: {
      background: '#333436',
      paper: 'black',
      text: 'white',
    },
    footer: '#333436',
    topBar: '#333436',
    topbarHover: '#EF5350',
    redText: '#EF5350',
    white: '#eeeeee',
    textColor: '#ffffff',
    heroCaption: '#B0BEC5',
    drawerButton: alpha('#90caf9', 0.1),
    drawerButtonHover: alpha('#90caf9', 0.1),
    drawerButtonBright: alpha('#90caf9', 0.6),
    aidropdown: {
      blue: {
        50: '#F0F7FF',
        100: '#C2E0FF',
        200: '#99CCF3',
        300: '#66B2FF',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E6',
        700: '#0059B3',
        800: '#004C99',
        900: '#003A75',
      },
      grey: {
        50: '#1C2025',
        100: '#303740',
        200: '#434D5B',
        300: '#6B7A90',
        400: '#9DA8B7',
        500: '#B0B8C4',
        600: '#C7D0DD',
        700: '#DAE2ED',
        800: '#E5EAF2',
        900: '#F3F6F9',
      },
    },
    scrollBar: '#888',
    scrollBarHover: '#555',
    scrollBarBg: '#303030',
    black: '#000000',
  },
})
