import { Box, styled, Typography } from '@mui/material'
import { Button } from 'react-scroll'

// MainContainer for centering content
const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
}))

// ColorBackground with a light background color
const ColorBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.profile.bg,
  minHeight: '40vh',
  width: '100%',
}))

// ProfileContainer with responsive width using media queries
const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  marginTop: '-150px',
  backgroundColor: theme.palette.profile.imgBg,
  minHeight: '40vh',
  marginBottom: '50px',
  width: '80%',
  maxWidth: '95%',
  borderRadius: '20px',

  [theme.breakpoints.down('sm')]: {
    width: '92%',
  },
}))

const ImageContainer = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  marginBottom: '3rem',
  width: '100%',
  maxWidth: '10rem',
  position: 'absolute',
  top: '-70px',

  [theme.breakpoints.down('xl')]: {
    maxWidth: '9rem',
    marginBottom: '0.2rem',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '8rem',
    top: '-50px',
    marginBottom: '0.2rem',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '6.8rem',
    top: '-50px',
    marginBottom: '0.2rem',
  },
}))

const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '50px',
  marginTop: '30px',
}))

const ProfileButton = styled(Button)(({ theme }) => ({
  padding: '15px 30px',
  backgroundColor: theme.palette.profile.button,
  borderRadius: '30px',
  color: theme.palette.secondary.main,
  textAlign: 'center',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}))

const UsernameTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '15px',

  // Adjustments for mobile screens
  [theme.breakpoints.only('xl')]: {
    fontSize: '2rem',
    marginTop: '6rem',
    marginBottom: '10px',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2rem',
    marginBottom: '8px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
    marginBottom: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
    marginTop: '1.5rem',
    marginBottom: '8px',
  },
}))

const EmailTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginBottom: '10px',

  // Adjustments for mobile screens
  [theme.breakpoints.only('xl')]: {
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem',
    marginBottom: '8px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
    marginBottom: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '8px',
  },
}))

export {
  MainContainer,
  ColorBackground,
  ProfileContainer,
  ImageContainer,
  ButtonContainer,
  ProfileButton,
  UsernameTypography,
  EmailTypography,
}
