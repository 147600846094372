import React, { useEffect, useState } from 'react'
import {
  Typography,
  TextField,
  Box,
  Button,
  IconButton,
  Paper,
  InputAdornment,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Visibility, VisibilityOff, Edit, Save, Delete } from '@mui/icons-material'
import { API_KEY_LENGTHS, USEISMOBILE } from '../../constants/Constants'
import {
  addUserKey,
  getUserKeys,
  updateUserKeys,
  deleteUserKeys,
} from '../../redux/action/UserSettingsAction'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../AuthContext'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { setChatGPTKey, setClaudeKey, setGeminiKey } from '../../redux/slice/UserSettings'
import { useTranslation } from 'react-i18next'

const KeyConfiguration = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = USEISMOBILE()
  const { ready, t } = useTranslation()

  // States for API key values
  // const [chatGPTKey, setChatGPTKey] = useState('')
  // const [claudeKey, setClaudeKey] = useState('')
  // const [geminiKey, setGeminiKey] = useState('')

  // State to track if keys are saved
  const [isChatGPTKeySaved, setIsChatGPTKeySaved] = useState(false)
  const [isClaudeKeySaved, setIsClaudeKeySaved] = useState(false)
  const [isGeminiKeySaved, setIsGeminiKeySaved] = useState(false)

  const [chatGPTKeyInDb, setChatGPTInDb] = useState('')
  const [claudeKeyInDb, setClaudeKeyInDb] = useState('')
  const [geminiKeyInDb, setGeminiKeyInDb] = useState('')

  const [chatGPTKeyError, setChatGPTKeyError] = useState(false)

  const [formErrors, setFormErrors] = useState({
    openai: '',
    claude: '',
    gemini: '',
  })

  const { userInfo } = useAuth()
  const { chatGPTKey, claudeKey, geminiKey } = useSelector((state) => state.UserSettings)

  const validationRules = {
    openai: {
      required: true,
      minLength: API_KEY_LENGTHS.openai.min,
      maxLength: API_KEY_LENGTHS.openai.max,
    },
    claude: {
      required: true,
      minLength: API_KEY_LENGTHS.claudeai.min,
      maxLength: API_KEY_LENGTHS.claudeai.max,
    },
    gemini: {
      required: true,
      minLength: API_KEY_LENGTHS.gemini.min,
      maxLength: API_KEY_LENGTHS.gemini.max,
    },
  }

  const validateField = (name, value) => {
    let error = ''

    if (validationRules[name].required && (!value || value.trim() === '')) {
      error = 'This field cannot be empty'
    } else if (value.length < validationRules[name].minLength) {
      error = `Minimum length is ${validationRules[name].minLength}`
    } else if (value.length > validationRules[name].maxLength) {
      error = `Maximum length is ${validationRules[name].maxLength}`
    }

    setFormErrors({ ...formErrors, [name]: error })
  }

  const handleChange = (name, value) => {
    // validateField(name, value)

    switch (name) {
      case 'openai':
        dispatch(setChatGPTKey(value))
        break
      case 'claude':
        dispatch(setClaudeKey(value))
        break
      case 'gemini':
        dispatch(setGeminiKey(value))
        break
      default:
        break
    }
  }

  const fetchUserKeys = () => {
    try {
      dispatch(
        getUserKeys({
          userId: userInfo?.userId,
          meta: {
            onSuccess: (data) => {
              const keys = data?.data?.data?.aiConfigs || []

              const updatedKeys = {
                chatGPTKey: '',
                claudeKey: '',
                geminiKey: '',
                chatGPTKeyInDb: '',
                claudeKeyInDb: '',
                geminiKeyInDb: '',
              }
              keys.forEach((key) => {
                switch (key.platform) {
                  case 'openai':
                    updatedKeys.chatGPTKey = key.platformKey
                    updatedKeys.chatGPTKeyInDb = key.platformKey
                    break
                  case 'claude':
                    updatedKeys.claudeKey = key.platformKey
                    updatedKeys.claudeKeyInDb = key.platformKey
                    break
                  case 'gemini':
                    updatedKeys.geminiKey = key.platformKey
                    updatedKeys.geminiKeyInDb = key.platformKey
                    break
                  default:
                    break
                }
              })
              dispatch(setChatGPTKey(updatedKeys.chatGPTKey))
              dispatch(setClaudeKey(updatedKeys.claudeKey))
              dispatch(setGeminiKey(updatedKeys.geminiKey))
              setChatGPTInDb(updatedKeys.chatGPTKeyInDb)
              setClaudeKeyInDb(updatedKeys.claudeKeyInDb)
              setGeminiKeyInDb(updatedKeys.geminiKeyInDb)

              // Set saved state based on whether keys are present
              setIsChatGPTKeySaved(!!updatedKeys.chatGPTKeyInDb)
              setIsClaudeKeySaved(!!updatedKeys.claudeKeyInDb)
              setIsGeminiKeySaved(!!updatedKeys.geminiKeyInDb)
            },
            onError: (error) => {
              console.error('Fetching keys failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const addUserKeys = (val, model) => {
    const obj = { [model]: val }
    try {
      dispatch(
        addUserKey({
          userId: userInfo?.userId,
          obj,
          meta: {
            onSuccess: (data) => {
              toast.success(data?.data?.message)
              fetchUserKeys()
            },
            onError: (error) => {
              // toast.error(error.response.data.detail[0].msg)
              toast.error(error.response.data.message)
              console.error('Fetching keys failed :', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const deleteKeys = (val, model) => {
    const obj = { [model]: val }
    try {
      dispatch(
        deleteUserKeys({
          userId: userInfo?.userId,
          obj,
          meta: {
            onSuccess: (data) => {
              toast.success(data?.data?.message)
              fetchUserKeys()
            },
            onError: (error) => {
              toast.error(error.response.data.detail[0].msg)
              console.error('Fetching keys failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const updateKey = (val, model) => {
    const obj = { [model]: val }
    try {
      dispatch(
        updateUserKeys({
          userId: userInfo?.userId,
          obj,
          meta: {
            onSuccess: (data) => {
              toast.success(data?.data?.message)
              fetchUserKeys()
            },
            onError: (error) => {
              toast.error(error.response.data.detail[0].msg)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  const handleSave = (model) => {
    switch (model) {
      case 'openai':
        if (chatGPTKeyInDb !== chatGPTKey) {
          if (chatGPTKeyInDb) {
            updateKey(chatGPTKey, model)
          } else {
            addUserKeys(chatGPTKey, model)
          }
        }
        break
      case 'claude':
        if (claudeKeyInDb !== claudeKey) {
          if (claudeKeyInDb) {
            updateKey(claudeKey, model)
          } else {
            addUserKeys(claudeKey, model)
          }
        }
        break
      case 'gemini':
        if (geminiKeyInDb !== geminiKey && geminiKey) {
          updateKey(geminiKey, model)
        } else if (geminiKey) {
          addUserKeys(geminiKey, model)
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (userInfo?.userId) {
      fetchUserKeys()
    }
  }, [userInfo?.userId])

  // Load saved state from localStorage on component mount
  useEffect(() => {
    const savedChatGPTKey = localStorage.getItem('chatGPTKeySaved') === 'true'
    const savedClaudeKey = localStorage.getItem('claudeKeySaved') === 'true'
    const savedGeminiKey = localStorage.getItem('geminiKeySaved') === 'true'

    setIsChatGPTKeySaved(savedChatGPTKey)
    setIsClaudeKeySaved(savedClaudeKey)
    setIsGeminiKeySaved(savedGeminiKey)
  }, [])

  const handeleDeleteClick = (model) => {
    switch (model) {
      case 'openai':
        handleDeleteConfirmation(chatGPTKey, model)
        break
      case 'claude':
        handleDeleteConfirmation(claudeKey, model)
        break
      case 'gemini':
        handleDeleteConfirmation(geminiKey, model)
        break
      default:
        break
    }
  }
  const handleDeleteConfirmation = (key, model) => {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to Delete this Key?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteKeys(key, model)
      } else {
        // example codes to clear up
      }
    })
  }

  return (
    <Box padding={3}>
      <Typography
        variant="h4"
        style={{ color: theme.palette.setting.mainTitle, marginBottom: '1rem' }}
      >
        {ready ? t('settings.keyConfiguration') : ''}
      </Typography>

      {/* Chat GPT Section */}
      <Paper elevation={3} style={{ marginBottom: '1rem' }}>
        <Box padding={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {ready ? t('settings.chatGPT') : ''}
            </Typography>
            {!isChatGPTKeySaved && ( // Show save button only if not saved
              <IconButton
                onClick={() => {
                  handleSave('openai')
                  setIsChatGPTKeySaved(true) // Mark as saved
                  localStorage.setItem('chatGPTKeySaved', 'true') // Save state to localStorage
                }}
              >
                <Tooltip title="save" placement="top" arrow>
                  <Save />
                </Tooltip>
              </IconButton>
            )}
            {isChatGPTKeySaved && ( // Show delete button only if saved
              <IconButton onClick={() => handeleDeleteClick('openai')}>
                <Tooltip title="delete" placement="top" arrow>
                  <Delete />
                </Tooltip>
              </IconButton>
            )}
          </Box>
          <TextField
            value={chatGPTKey}
            placeholder={ready ? t('settings.chatGPTPlaceholder') : ''}
            variant="outlined"
            fullWidth
            margin="normal"
            // type={isChatGPTKeySaved ? 'password' : 'text'} // Switch to password field if saved
            type="password"
            helperText={formErrors.openai}
            error={formErrors.openai}
            inputProps={{
              maxLength: API_KEY_LENGTHS.openai.max,
            }}
            disabled={isChatGPTKeySaved} // Disable if saved
            onChange={(e) => handleChange('openai', e.target.value)}
          />
        </Box>
      </Paper>

      {/* Claude Section */}
      <Paper elevation={3} style={{ marginBottom: '1rem' }}>
        <Box padding={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {ready ? t('settings.claude') : ''}
            </Typography>
            {!isClaudeKeySaved && (
              <IconButton
                onClick={() => {
                  handleSave('claude')
                  setIsClaudeKeySaved(true) // Mark as saved
                  localStorage.setItem('claudeKeySaved', 'true') // Save state to localStorage
                }}
              >
                <Tooltip title="save" placement="top" arrow>
                  <Save />
                </Tooltip>
              </IconButton>
            )}
            {isClaudeKeySaved && (
              <IconButton onClick={() => handeleDeleteClick('claude')}>
                <Tooltip title="delete" placement="top" arrow>
                  <Delete />
                </Tooltip>
              </IconButton>
            )}
          </Box>
          <TextField
            value={claudeKey}
            placeholder={ready ? t('settings.claudePlaceholder') : ''}
            variant="outlined"
            fullWidth
            margin="normal"
            // type={isClaudeKeySaved ? 'password' : 'text'} // Switch to password field if saved
            type="password"
            helperText={formErrors.claude}
            error={formErrors.claude}
            inputProps={{
              maxLength: API_KEY_LENGTHS.claudeai.max,
            }}
            disabled={isClaudeKeySaved} // Disable if saved
            onChange={(e) => handleChange('claude', e.target.value)}
          />
        </Box>
      </Paper>

      {/* Gemini Section */}
      <Paper elevation={3} style={{ marginBottom: '1rem' }}>
        <Box padding={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {ready ? t('settings.gemini') : ''}
            </Typography>
            {!isGeminiKeySaved && (
              <IconButton
                onClick={() => {
                  handleSave('gemini')
                  setIsGeminiKeySaved(true) // Mark as saved
                  localStorage.setItem('geminiKeySaved', 'true') // Save state to localStorage
                }}
              >
                <Tooltip title="save" placement="top" arrow>
                  <Save />
                </Tooltip>
              </IconButton>
            )}
            {isGeminiKeySaved && (
              <IconButton onClick={() => handeleDeleteClick('gemini')}>
                <Tooltip title="delete" placement="top" arrow>
                  <Delete />
                </Tooltip>
              </IconButton>
            )}
          </Box>
          <TextField
            value={geminiKey}
            placeholder={ready ? t('settings.geminiPlaceholder') : ''}
            variant="outlined"
            fullWidth
            margin="normal"
            // type={isGeminiKeySaved ? 'password' : 'text'} // Switch to password field if saved
            type="password"
            helperText={formErrors.gemini}
            error={formErrors.gemini}
            sx={{
              cursor: isGeminiKeySaved ? 'not-allowed' : 'default',
              color: isGeminiKeySaved ? 'gray' : 'inherit',
            }}
            inputProps={{
              maxLength: API_KEY_LENGTHS.gemini.max,
            }}
            disabled={isGeminiKeySaved} // Disable if saved
            onChange={(e) => handleChange('gemini', e.target.value)}
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default KeyConfiguration
