import React from 'react'
import PropTypes from 'prop-types'
import logger from '.'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate the error
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // Log the error for detailed information
    logger.error(error, 'Error caught by ErrorBoundary', errorInfo)
    this.setState({ errorInfo })
  }

  render() {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === 'development') {
        // Display detailed error information in development
        return (
          <div
            style={{
              padding: '20px',
              border: '1px solid red',
              borderRadius: '5px',
              margin: '20px',
            }}
          >
            <p className="text-[25px]">Something went wrong!</p>
            <p className=" text-red-600">{this.state.error.toString()}</p>
            <p>Details: {this.state.errorInfo && this.state.errorInfo.componentStack}</p>
          </div>
        )
      } else {
        // Display a user-friendly error message in production
        return (
          <div
            style={{
              padding: '20px',
              border: '1px solid red',
              borderRadius: '5px',
              margin: '20px',
            }}
          >
            <h2>Something went wrong!</h2>
            <p>
              We apologize for the inconvenience. Our team has been notified, and we are working to
              resolve the issue.
            </p>
          </div>
        )
      }
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
