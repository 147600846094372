// Subscription.js
import React from 'react'
import { Typography, Box, Paper, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const Subscription = () => {
  const theme = useTheme()

  return (
    <Box padding={3}>
      <Typography variant="h4" style={{ color: theme.palette.primary.main, marginBottom: '1rem' }}>
        Subscription
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: '1rem', border: `1px solid ${theme.palette.primary.main}` }}
      >
        <Typography variant="h6">Your Subscription Plan</Typography>
        <Typography variant="body1">
          You are currently subscribed to the Premium plan. Enjoy additional features and benefits.
        </Typography>
        <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>
          Change Plan
        </Button>
      </Paper>
    </Box>
  )
}

export default Subscription
