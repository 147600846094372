import { Box, Stack, styled, Typography } from '@mui/material'

const CopyrightBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
const CopyrightTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.white,
  userSelect: 'none',
}))
const MainStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage.footer.bg,
  minHeight: '200px',
  justifyContent: 'space-evenly',
}))
const CompanyNameBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
const CompanyNameTYpography = styled(Box)(({ theme }) => ({
  color: theme.palette.landingPage.footer.title,
  fontSize: '2rem',
  marginBottom: '30px',
  userSelect: 'none',
}))

export { CopyrightBox, CopyrightTypography, MainStack, CompanyNameBox, CompanyNameTYpography }
