import { Grid, styled, Typography } from '@mui/material'

const HeadingTypography = styled(Typography)(({ isMobile, theme }) => ({
  color: theme.palette.landingPage.signIn.title,
  fontWeight: 600,
  fontSize: isMobile ? '24px' : '50px',
  textAlign: 'center',
}))

const CaptionTypography = styled(Typography)(({ isMobile, theme }) => ({
  color: theme.palette.landingPage.signIn.describe,
  lineHeight: '1.5rem',
  fontWeight: '700',
  fontSize: isMobile ? '1rem' : '1.4rem',
  marginTop: isMobile ? '15px' : '',
  userSelect: 'none',
  paddingTop: '24px',
  textAlign: 'center',
}))

const RedTypography = styled(Typography)(({ isMobile, theme }) => ({
  color: theme.palette.landingPage.signIn.title,
  fontSize: isMobile ? '24px' : '40px',
  marginTop: isMobile ? '15px' : '',
  fontWeight: '600',
  lineHeight: '2.5rem',
  letterSpacing: '1px',
  textAlign: 'center',
  userSelect: 'none',
}))

const MainGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const GoogleButton = styled(Grid)(() => ({
  backgroundColor: '#4285F4',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#357ae8',
  },
}))

export { HeadingTypography, CaptionTypography, RedTypography, MainGrid, GoogleButton }
