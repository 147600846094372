import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { authApi as Api } from '../../services/Api'

export const signIn = createAsyncThunk('signIn', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.login, payload)
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})
