import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Input, StyledSlider } from './SliderStyles'
import { Grid, Stack } from '@mui/material'

const SliderBox = ({ name, val, handleValChange, isPublished, min, max, step }) => {
  const [value, setValue] = useState(val)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    handleValChange(newValue)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 15px)', mb: 3 }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography gutterBottom sx={{ mb: '10px' }}>
          {name}
        </Typography>

        <Input
          value={value}
          size="small"
          onChange={(e) => handleChange(e, Number(e.target.value))}
          inputProps={{
            min: min,
            max: max,
            step: step,
            type: 'number',
            'aria-labelledby': 'input-slider',
            disabled: isPublished,
            color: 'black',
            '& .MuiInputBase-root.Mui-disabled': {
              color: '#000000',
              backgroundColor: '#f0f0f0',
            },
          }}
          disabled={isPublished}
        />
      </Stack>
      <StyledSlider
        aria-label={name}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off" // Turn off value label
        min={min}
        max={max}
        step={step}
        disabled={isPublished}
      />
      <Grid item></Grid>
    </Box>
  )
}

SliderBox.propTypes = {
  name: PropTypes.string.isRequired,
  val: PropTypes.number.isRequired,
  handleValChange: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
}

export default SliderBox
