import React, { useState } from 'react'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { USEISMOBILE } from '../../constants/Constants'
import { navigationRoutes } from '../../router/Navigation'
import { useNavigate } from 'react-router-dom'
import CommonStyles from '../../assets/styles/CommonStyles'
import {
  ColorText,
  StyledBox,
  StyledButton,
  StyledContent,
  StyledContentTypography,
  StyledTypography,
} from './underMaintanceStyles'
import { useTranslation } from 'react-i18next'

const UnderMaintenance = () => {
  const isMobile = USEISMOBILE()
  const navigate = useNavigate()
  const { ready, t } = useTranslation()

  const [isImageLoaded, setIsImageLoaded] = useState(true)

  const handleImage = () => {
    setIsImageLoaded(false)
  }

  return (
    <StyledBox isMobile={isMobile}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <StyledTypography variant="h4" isMobile={isMobile}>
            {t('maintenance.title')}
          </StyledTypography>
          <StyledContentTypography variant="h5" isMobile={isMobile}>
            {t('maintenance.message')}
          </StyledContentTypography>
          <StyledButton
            variant="contained"
            onClick={() => navigate(navigationRoutes.main.landingPage)}
            sx={{ mt: 3 }}
          >
            {t('maintenance.button')}
          </StyledButton>
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default UnderMaintenance
