import React, { useState } from 'react'
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Dialog,
  Badge,
  Typography,
  AccordionDetails,
  Tooltip,
  DialogActions,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DataObjectIcon from '@mui/icons-material/DataObject'
import { useTheme } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledBadge,
  StyledCloseIcon,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
} from './testVariableStyles'
import VariableHelper from '../../variableHelper'
import { USEISMOBILE, USEISTAB } from '../../../constants/Constants'
import {
  setVariableOpen,
  setVariables,
  setVariablesCompare,
} from '../../../redux/slice/Conversation'
import CommonStyles from '../../../assets/styles/CommonStyles'

export const TestVariables = ({
  isPublished,
  component,
  variableOpen,
  handleToggle,
  //   lclVariables,
  setLclVariablesInital,
}) => {
  const theme = useTheme()
  const isMobile = USEISMOBILE()
  const isTab = USEISTAB()
  const dispatch = useDispatch()

  const { variables, variableKey, variableKeyCompare } = useSelector((state) => state.conversations)

  const [lclVariables, setLclVariables] = useState(variables)

  // Handle variable change
  const handleVariableChange = (name, newValue) => {
    const updateVariables = lclVariables
    const updateIndex = updateVariables.findIndex((variable) => variable.key === name)
    const updatedVar = [...updateVariables]

    if (updateIndex !== -1) {
      updatedVar[updateIndex] = { key: name, value: newValue }
    } else {
      updatedVar.push({ key: name, value: newValue })
    }

    setLclVariables(updatedVar)
    setLclVariablesInital(updatedVar)
  }

  const variableData = variableKey
  const variableValues = lclVariables

  return (
    <Box>
      {variableKey?.length > 0 && (
        <StyledIconButton
          onClick={() => {
            handleToggle()
          }}
        >
          <StyledBadge badgeContent={variableKey?.length}>
            <DataObjectIcon fontSize="large" />
          </StyledBadge>
        </StyledIconButton>
      )}

      {/* Dialog for Variables */}
      <Dialog
        open={variableOpen}
        // onClose={handleToggle} // Allows the dialog to close on outside click or ESC
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
            minWidth: isMobile ? '90%' : isTab ? '100%' : '60%',
            height: '80%',
          },
          '& .MuiAccordion-root': {
            borderRadius: '8px',
          },
        }}
      >
        <StyledDialogTitle isMobile={isMobile} isTab={isTab}>
          {isPublished ? 'Default Variables' : 'Manage Variables'}
          <IconButton
            aria-label="close"
            onClick={handleToggle}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Tooltip title="Close">
              <StyledCloseIcon />
            </Tooltip>
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          {variableData?.length === 0 ? (
            <Stack alignItems="center" justifyContent="center" height="auto">
              <VariableHelper />
            </Stack>
          ) : (
            <Stack direction="column" spacing={2}>
              {variableData?.map((name, i) => (
                <StyledAccordion key={i} defaultExpanded>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${i}-content`}
                    id={`panel-${i}-header`}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>{name}</Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <TextField
                      variant="outlined"
                      size="small"
                      multiline
                      minRows={1}
                      value={variableValues.find((variable) => variable.key === name)?.value}
                      onChange={(event) => handleVariableChange(name, event.target.value)}
                      fullWidth
                      InputProps={{
                        style: {
                          color: theme.palette.text.primary,
                          borderColor: theme.palette.primary.main,
                          borderRadius: '8px',
                        },
                      }}
                      disabled={isPublished}
                    />
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Stack>
          )}
        </StyledDialogContent>
        <DialogActions sx={{ justifyContent: 'right', padding: '16px 16px' }}>
          <CommonStyles.PrimaryButton onClick={handleToggle}>Save</CommonStyles.PrimaryButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
