import { CircularProgress } from '@mui/material'
import React from 'react'
import { StyledBackdrop } from './loaderBackdropStyles'

const LoaderBackdrop = ({ loading }) => {
  return (
    <StyledBackdrop open={loading}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  )
}

export default LoaderBackdrop
