import React, { createContext, useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null)
  const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)

  useEffect(() => {
    // Load userInfo from local storage if available
    const encodedData = localStorage.getItem(STORAGE.USER_INFO)
    if (encodedData) {
      try {
        const decodedData = atob(encodedData)
        const userData = JSON.parse(decodedData)
        setUserInfo(userData)
      } catch (error) {
        console.error('Failed to decode user data from local storage', error)
      }
    }
  }, [])

  // const login = (userData) => {
  //   const { access_token, refresh_token, user } = userData
  //   const user_info = JSON.stringify(user)
  //   const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
  //   localStorage.setItem(STORAGE.USER_INFO, btoa(user_info))
  //   localStorage.setItem(STORAGE.TOKEN, btoa(access_token))
  //   localStorage.setItem(STORAGE.REFRESH, btoa(refresh_token))
  //   setUserInfo(user)
  // }
  const login = (userData) => {
    const user_info = JSON.stringify(userData?.data?.user)
    const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
    localStorage.setItem(STORAGE.USER_INFO, btoa(user_info))
    localStorage.setItem(STORAGE.TOKEN, btoa(userData?.data?.access_token))
    localStorage.setItem(STORAGE.REFRESH, btoa(userData?.data?.refresh_token))
    setUserInfo(userData?.data?.user)
  }

  const logout = async () => {
    const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
    localStorage.removeItem(STORAGE.TOKEN)
    localStorage.removeItem(STORAGE.REFRESH)
    localStorage.removeItem(STORAGE.USER_INFO)
    localStorage.removeItem('persist:root')
    toast.success("You're logged out. Remember, you're always welcome back!")
  }

  return <AuthContext.Provider value={{ userInfo, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthContext
