import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid, TextField } from '@mui/material'
import { ModalBox } from './renameModalStyles'
import CommonStyles from '../../../assets/styles/CommonStyles'

const RenameModal = ({
  title,
  name,
  handleChangeInput,
  open,
  handleClose,
  handleSubmit,
  validateInput, // Pass in validation function
  setError,
  error,
  loading,
  setLoading,
}) => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (open) {
      setShowError(false)
      setError('')
    }
  }, [open])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      validateAndSubmit()
    }
  }

  const handleChange = (event) => {
    handleChangeInput(event)
    if (showError) {
      validateInput(event.target.value)
    }
  }

  const validateAndSubmit = () => {
    setShowError(true)
    if (validateInput(name)) {
      handleSubmit()
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {title}
        </Typography>
        <TextField
          id="outlined-basic"
          label="Name"
          value={name}
          variant="outlined"
          fullWidth
          sx={{ mt: 3 }}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus
          error={!!error && showError}
          helperText={showError ? error : ''}
        />
        <Grid container justifyContent="flex-end">
          <CommonStyles.PrimaryButton
            variant="contained"
            sx={{ mt: 2 }}
            disabled={loading}
            onClick={validateAndSubmit}
          >
            Rename
          </CommonStyles.PrimaryButton>
        </Grid>
      </ModalBox>
    </Modal>
  )
}

export default RenameModal
