import React, { useEffect, useRef, useState } from 'react'
import { MainBox, UserPromptBox, UserTextField } from './studioInputStyles'
import { useTheme } from '@mui/material'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAssistantValue,
  setChatSummary,
  setChatSummaryCompare,
  setMessages,
  setMessagesCompare,
  setUserValue,
  setUserValueCompare,
  setAssistantValueCompare,
} from '../../redux/slice/Conversation'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../router/Navigation'
import { v4 as uuidv4 } from 'uuid'
import { claudeChat, geminiChat, openaiChat } from '../../redux/action/PlaygroundAction'
import { toast } from 'react-toastify'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useTranslation } from 'react-i18next'
import SpeechToText from '../speechToText'
import { useAuth } from '../../AuthContext'
import CommonStyles from '../../assets/styles/CommonStyles'
import { checkMessageSequence, isAllVariablePresent, replaceVariables } from '../../util/helpers'
import { USEISMOBILE } from '../../constants/Constants'

export const StudioInput = ({
  component,
  selectedCategory,
  selectedCategoryCompare,
  variables,
  captureVariables,
  handleToggle,
}) => {
  const Theme = useTheme()
  const [role, setRole] = useState('user')

  const {
    selectedModel,
    systemValue,
    userValue,
    userValueCompare,
    messages,
    messagesCompare,
    isPublished,
    assistantValue,
    assistantValueCompare,
    responseFormat,
    responseFormatCompare,
    jsonMode,
    jsonModeCompare,
    parameters,
    parametersCompare,
  } = useSelector((state) => state.conversations)
  const { selectedProjectId, selectedFolderId, selectedPrompt, selectedPromptVersion } =
    useSelector((state) => state.UserProjects)

  const [runLoading, setRunLoading] = useState(false)

  const inputRef = useRef(null)

  // Speech to text implementation
  const { resetTranscript } = useSpeechRecognition()

  const {
    selectedModelCompare,
    systemValueCompare,
    variablesCompare,
    variableKeyCompare,
    variableKey,
  } = useSelector((state) => state.conversations)

  const { projectsData } = useSelector((state) => state.UserProjects)

  const [isMicProcessing, setIsMicProcessing] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, ready } = useTranslation()
  const { userInfo } = useAuth()
  const userId = userInfo?.userId
  const ismobile = USEISMOBILE()

  const handleRole = () => {
    if (component === 1) {
      if (selectedModel === 'claudeai') {
        return
      } else {
        setRole((prevRole) => (prevRole === 'user' ? 'assistant' : 'user'))
      }
    }
    if (component === 2) {
      if (selectedModelCompare === 'claudeai') {
        return
      } else {
        setRole((prevRole) => (prevRole === 'user' ? 'assistant' : 'user'))
      }
    }
  }

  const configurationErrorAlert = (model) => {
    Swal.fire({
      title: 'Configuration Error',
      text: 'API Key for ' + model + ' is not Configured',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Go to Settings',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        navigateToSettings()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle exit button action, if any
      }
    })
  }
  const navigateToSettings = () => {
    navigate(navigationRoutes.user.settings)
  }

  const handleChange = (event) => {
    const value = event.target.value
    let allMessage = ''
    component === 2
      ? messagesCompare.map((item) => {
          return (allMessage += item.content)
        })
      : messages.map((item) => {
          return (allMessage += item.content)
        })
    component === 2
      ? captureVariables(systemValueCompare + value + allMessage, component)
      : captureVariables(systemValue + value + allMessage, component)

    // Handle role-based value assignmentssss
    if (role === 'assistant') {
      if (component === 1) {
        dispatch(setAssistantValue(value)) // Set normal assistant value
      } else {
        dispatch(setAssistantValueCompare(value)) // Handle comparison value for assistant
      }
    } else {
      // Handle user value assignment
      if (component === 1) {
        dispatch(setUserValue(value)) // Set normal user value
      } else {
        dispatch(setUserValueCompare(value)) // Handle comparison value for user
      }
    }
  }

  const setChatHistory = (role, value) => {
    let messages
    if (component === 2) {
      messages = {
        _id: uuidv4(),
        role: role,
        content: value,
        model: selectedModelCompare,
        category: selectedCategoryCompare,
      }
      dispatch(setMessagesCompare(messages))
    } else if (component === 1) {
      messages = {
        _id: uuidv4(),
        role: role,
        content: value,
        model: selectedModel,
        category: selectedCategory,
      }
      dispatch(setMessages(messages))
    }
    return messages
  }

  const messagePop = () => {
    if (component === 2) {
      const updatedMessages = [...messagesCompare]
      if (updatedMessages.length > 0) {
        updatedMessages.length = updatedMessages.length - 1
      }
      dispatch(setMessagesCompare(updatedMessages))
    } else if (component === 1) {
      const updatedMessages = [...messages]
      if (updatedMessages.length > 0) {
        updatedMessages.length = updatedMessages.length - 1
      }
      dispatch(setMessages(updatedMessages))
    }
  }

  const handleAddComponent1 = () => {
    if (userValue.trim() === '' && assistantValue.trim() === '') {
      return
    }
    const currentValue = role === 'assistant' ? assistantValue : userValue
    setChatHistory(role, currentValue)
    SpeechRecognition.stopListening()
    resetTranscript()
    role === 'assistant' ? dispatch(setAssistantValue('')) : dispatch(setUserValue(''))
  }

  const handleAddComponent2 = () => {
    if (userValueCompare.trim() === '' && assistantValueCompare.trim() === '') {
      return
    }
    const currentValue = role === 'assistant' ? assistantValueCompare : userValueCompare
    setChatHistory(role, currentValue)
    SpeechRecognition.stopListening()
    resetTranscript()
    role === 'assistant'
      ? dispatch(setAssistantValueCompare(''))
      : dispatch(setUserValueCompare(''))
  }

  const handleAdd = () => {
    if (component === 1) {
      handleAddComponent1()
    } else if (component === 2) {
      handleAddComponent2()
    }
  }

  const handleRun = (inputValue) => {
    // Common validations
    if (projectsData?.folders?.length === 0) {
      toast.warning('Please create a Folder first')
      return
    }

    if (!selectedPrompt?.promptId) {
      toast.warning('Please Select a Prompt first')
      return
    }

    if (!selectedPromptVersion) {
      toast.warning('Please Select a Version first')
      return
    }

    if (selectedModel === 'claudeai' && !checkMessageSequence(messages)) {
      toast.warning('Not in sequence')
      return
    }

    if (component === 1 && jsonMode && !responseFormat) {
      toast.warning('Please enter a valid JSON Schema')
      return
    }

    if (component === 2 && jsonModeCompare && !responseFormatCompare) {
      toast.warning('Please enter a valid JSON Schema')
      return
    }

    // Component-specific handling
    if (component === 2) {
      handleComponentTwo(inputValue)
    } else if (component === 1) {
      handleComponentOne(inputValue)
    }
  }

  // Function to handle component 1 logic
  const handleComponentOne = (inputValue) => {
    // Check if input and assistant value are empty for component 1
    if (inputValue.trim() === '' && assistantValue.trim() === '') {
      toast.warning('Please Enter a message')
      return
    }

    if (!isAllVariablePresent(variables, variableKey)) {
      toast.warning('Please fill all variable values')
      handleToggle()
      return
    }

    if (assistantValue) {
      setChatHistory('assistant', assistantValue)
      dispatch(setAssistantValue(''))
      return
    }

    const currentValue = role === 'assistant' ? assistantValue : userValue
    processRun(role, currentValue, selectedModel, messages)
  }

  // Function to handle component 2 logic
  const handleComponentTwo = (inputValue) => {
    // Check if input and assistantValueCompare are empty for component 2
    if (inputValue.trim() === '' && assistantValueCompare.trim() === '') {
      toast.warning('Please Enter a message')
      return
    }

    if (!isAllVariablePresent(variablesCompare, variableKeyCompare)) {
      toast.warning('Please fill all variable values')
      handleToggle()
      return
    }

    if (assistantValueCompare) {
      setChatHistory('assistant', assistantValueCompare)
      dispatch(setAssistantValueCompare(''))
      return
    }

    const currentValue = role === 'assistant' ? assistantValueCompare : userValueCompare
    processRun(role, currentValue, selectedModelCompare, messagesCompare)
  }

  // Common function to process the run
  const processRun = (role, currentValue, selectedModel, messageList) => {
    const newMessage = setChatHistory(role, currentValue)
    const updatedMessages = [...messageList, newMessage]

    switch (selectedModel) {
      case 'openai':
        chatFn(updatedMessages, 'openai')
        break
      case 'claudeai':
        chatFn(updatedMessages, 'claude')
        break
      case 'gemini':
        chatFn(updatedMessages, 'gemini')
        break
      default:
        break
    }
  }

  const chatFn = (updatedMsg, platform) => {
    setRunLoading(true)

    const replacedMessages = updatedMsg.map((message) => ({
      role: message.role,
      content: replaceVariables(
        typeof message.content === 'string' ? message.content : JSON.stringify(message.content),
        variables,
      ),
    }))

    const replacedMessagesCompare = updatedMsg.map((message) => ({
      role: message.role,
      content: replaceVariables(
        typeof message.content === 'string' ? message.content : JSON.stringify(message.content),
        variablesCompare,
      ),
    }))

    // const parserdFormat = responseFormat && JSON.parse(responseFormat)

    const payload = {
      messages: component === 2 ? replacedMessagesCompare : replacedMessages,
      ...((component === 2 ? selectedModelCompare : selectedModel) !== 'claudeai' && {
        user_message:
          component === 2
            ? replaceVariables(userValueCompare, variablesCompare)
            : replaceVariables(userValue, variables),
        assistant_message:
          component === 2
            ? replaceVariables(assistantValueCompare, variablesCompare)
            : replaceVariables(assistantValue, variables),
      }),
      projectId: selectedProjectId ?? '', // Add default or validation here if necessary
      folderId: selectedFolderId ?? '', // Same here for validation
      promptId: selectedPrompt?.promptId ?? '', // Check for valid promptId
      isPublished: isPublished ?? false, // Set default to false
      model: component === 2 ? selectedCategoryCompare : selectedCategory,
      platform:
        component === 2
          ? selectedModelCompare === 'claudeai'
            ? 'claude'
            : selectedModelCompare
          : selectedModel === 'claudeai'
            ? 'claude'
            : selectedModel,
      // platform: platform,
      system_message:
        component === 2
          ? replaceVariables(systemValueCompare, variablesCompare)
          : replaceVariables(systemValue, variables),
      temperature:
        component === 2
          ? parametersCompare[selectedModelCompare]?.temperature
          : parameters[selectedModel]?.temperature,
      maxTokens:
        component === 2
          ? parametersCompare[selectedModelCompare]?.maxTokens
          : parameters[selectedModel]?.maxTokens,
      topP:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.topP
          : parameters[selectedModel]?.topP,
      frequencyPenalty:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.frequencyPenalty
          : parameters[selectedModel]?.frequencyPenalty,
      presencePenalty:
        platform === 'openai' && component === 2
          ? parametersCompare[selectedModelCompare]?.presencePenalty
          : parameters[selectedModel]?.presencePenalty,

      ...(component === 1 &&
        selectedModel === 'openai' &&
        jsonMode &&
        !selectedCategory.includes('gpt-3.5-turbo') && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
        }),
      ...(component === 2 &&
        selectedModelCompare === 'openai' &&
        jsonModeCompare &&
        !selectedCategoryCompare.includes('gpt-3.5-turbo') && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormatCompare) },
        }),

      ...(component === 1 &&
        selectedModel === 'gemini' &&
        jsonMode && {
          response_format: JSON.parse(responseFormat),
        }),
      ...(component === 2 &&
        selectedModelCompare === 'gemini' &&
        jsonModeCompare && {
          response_format: JSON.parse(responseFormatCompare),
        }),
    }

    let dispatchAction
    if (platform === 'openai') {
      dispatchAction = openaiChat
    } else if (platform === 'claude') {
      dispatchAction = claudeChat
    } else if (platform === 'gemini') {
      dispatchAction = geminiChat
    }

    try {
      dispatch(
        dispatchAction({
          userData: payload,
          userId,
          meta: {
            onSuccess: (data) => {
              setChatHistory('assistant', data?.data?.response)
              if (component === 2) {
                dispatch(setChatSummaryCompare(data.summarized_content))
              } else {
                dispatch(setChatSummary(data.summarized_content))
              }
              SpeechRecognition.stopListening()
              resetTranscript()
              if (component === 1) {
                dispatch(setUserValue(''))
              }
              if (component === 2) {
                dispatch(setUserValueCompare(''))
              }
              dispatch(setAssistantValue(''))
              setRunLoading(false)
            },
            onError: (error) => {
              if (error?.data?.status_code === 417) {
                configurationErrorAlert(selectedModel)
              } else {
                toast.error(error?.data?.message || 'An error occurred')
              }

              setRunLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
      setRunLoading(false)
    }
  }

  useEffect(() => {
    if (selectedModel === 'claudeai') {
      setRole('user')
    }
  }, [selectedModel])

  return (
    <MainBox>
      <UserPromptBox>
        <Box overflow="auto" py={0} display="flex" flexDirection="row">
          <UserTextField
            ref={inputRef}
            variant="outlined"
            value={
              role === 'assistant'
                ? component === 1
                  ? assistantValue
                  : assistantValueCompare
                : component === 1
                  ? userValue
                  : userValueCompare
            }
            disabled={component === 1 && isPublished}
            onChange={handleChange}
            onKeyDown={(e) =>
              e.ctrlKey &&
              e.key === 'Enter' &&
              handleRun(component === 1 ? userValue : userValueCompare)
            }
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            placeholder={
              ready
                ? t(
                    `studio.studioInput.${role === 'assistant' ? 'assistantPlaceholder' : 'userPlaceholder'}`,
                  )
                : `Enter ${role === 'assistant' ? 'Assistant' : 'User'} message`
            }
            InputProps={{ sx: { borderRadius: 2, borderColor: Theme.palette.primary.main } }}
          />
        </Box>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack px={1} gap={2}>
            {component === 1 && selectedModel !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                variant="contained"
                onClick={handleRole}
                disabled={runLoading || isMicProcessing || isPublished} // Adjusted for component === 1
              >
                {ready
                  ? t(`studio.studioInput.${role === 'user' ? 'user' : 'assistance'}`)
                  : role === 'user'
                    ? 'User'
                    : 'Assistant'}
              </CommonStyles.PrimaryButton>
            )}

            {component === 2 && selectedModelCompare !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                variant="contained"
                onClick={handleRole}
                disabled={runLoading} // Adjusted for component === 2
                ismobile={ismobile}
              >
                {ready
                  ? t(`studio.studioInput.${role === 'user' ? 'user' : 'assistance'}`)
                  : role === 'user'
                    ? 'User'
                    : 'Assistant'}
              </CommonStyles.PrimaryButton>
            )}
          </Stack>
          <Stack direction="row" px={1} gap={2}>
            {component === 1 && !isPublished && (
              <SpeechToText
                role={role}
                setAssistantValue={setAssistantValue}
                isMicProcessing={isMicProcessing}
                setIsMicProcessing={setIsMicProcessing}
                runLoading={runLoading}
              />
            )}
            {component === 1 && selectedModel !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                onClick={handleAdd}
                disabled={runLoading || isMicProcessing || (component === 1 && isPublished)}
                ismobile={ismobile}
              >
                {ready ? t('studio.studioInput.add') : 'ADD'}
              </CommonStyles.PrimaryButton>
            )}
            {component === 2 && selectedModelCompare !== 'claudeai' && (
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                onClick={handleAdd}
                disabled={runLoading || isMicProcessing || (component === 1 && isPublished)}
              >
                {ready ? t('studio.studioInput.add') : 'ADD'}
              </CommonStyles.PrimaryButton>
            )}

            <CommonStyles.PrimaryButton
              ismobile={ismobile}
              onClick={() => handleRun(component === 1 ? userValue : userValueCompare)}
              disabled={
                runLoading ||
                role === 'assistant' ||
                isMicProcessing ||
                (component === 1 && isPublished)
              }
            >
              {ready ? t('studio.studioInput.run') : 'Run Ctrl'}
            </CommonStyles.PrimaryButton>
          </Stack>
        </Stack>
      </UserPromptBox>
    </MainBox>
  )
}
