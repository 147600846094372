import { MenuItem } from '@mui/base'
import { Box, Menu, styled } from '@mui/material'
import { SCROLL_STYLE } from '../../constants/Constants'

const FlagBox = styled(Box)(() => ({
  width: 24,
  height: 24,
  borderRadius: '40%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8,
}))

const FlagBoxButton = styled(Box)(() => ({
  width: 24,
  height: 24,
  borderRadius: '40%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 1,
}))

const StyledMenu = styled(Menu)(({ theme }) => ({
  maxHeight: '400px',
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
    width: 161,
    overflowY: 'auto',
  },
}))

export { FlagBox, FlagBoxButton, StyledMenu }
