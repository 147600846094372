import React from 'react'
import { Typography } from '@mui/material'
import { useAuth } from '../../AuthContext'
import {
  ButtonContainer,
  ColorBackground,
  EmailTypography,
  ImageContainer,
  MainContainer,
  ProfileButton,
  ProfileContainer,
  UsernameTypography,
} from './profileStyles'
import { navigationRoutes } from '../../router/Navigation'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
  const { userInfo, logout } = useAuth()
  const navigate = useNavigate()

  const handleLogoutClick = () => {
    logout()
    navigate(navigationRoutes.main.landingPage)
  }

  return (
    <MainContainer>
      <ColorBackground />
      <ProfileContainer>
        <ImageContainer src={userInfo?.userProfile} alt="Profile" />
        <UsernameTypography variant={'h4'} gutterBottom marginTop={'60px'}>
          {userInfo?.userName}
        </UsernameTypography>
        <EmailTypography variant={'h5'}>{userInfo?.userEmail}</EmailTypography>
        <ButtonContainer>
          <ProfileButton onClick={handleLogoutClick}>Logout</ProfileButton>
        </ButtonContainer>
      </ProfileContainer>
    </MainContainer>
  )
}

export default Profile
