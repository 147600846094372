import { Box, Button, Stack, styled } from '@mui/material'

const CompareBox = styled(Box)(() => ({
  border: 0,
  flex: 0.5,
  px: 4,
  display: 'flex',
  flexDirection: 'row',
  // height: '200px',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: 'auto',
}))

const OptionBox = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  gap: 14,
}))

const CompareIconBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  height: '100%',
  cursor: 'pointer',
  padding: '6px',
}))

const PublishButton = styled(Button)(({ theme }) => ({
  marginRight: '10px',
  backgroundColor: theme.palette.studio.publishButton,
  color: theme.palette.primary.icon,
}))

const HistoryButton = styled(Button)(({ theme }) => ({
  marginRight: '5px',
  color: theme.palette.studio.historyButton,
}))

const MainBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  padding: '8px',
  height: 'auto',

  overflow: 'hidden',
}))

const ToggleButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: 6,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
  color: disabled ? theme.palette.action.disabled : theme.palette.secondary.main,
  cursor: disabled ? 'not-allowed' : 'pointer',
  border: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette.primary.dark,
  },
}))

const CopyButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: 6,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
  color: disabled ? theme.palette.action.disabled : theme.palette.secondary.main,
  cursor: disabled ? 'not-allowed' : 'pointer',
  border: 'none',
  boxShadow: 'none',
  padding: '6px',
  '&:hover': {
    boxShadow: 'none', // Remove hover box shadow
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette.primary.dark, // Optional hover background change
  },
}))

const CompareToggleStack = styled(Stack)(({ theme, disabled }) => ({
  width: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: '16px',
}))

const ActionButtonsStack = styled(Stack)(({ theme, disabled }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  spacing: '8px',
  width: '329px',
}))

export {
  CompareBox,
  CompareIconBox,
  HistoryButton,
  OptionBox,
  PublishButton,
  MainBox,
  CopyButton,
  ToggleButton,
  CompareToggleStack,
  ActionButtonsStack,
}
