export const isAllVariablePresent = (variablesArray, variableKeys) => {
  const temp = variableKeys.every((key) => {
    const variable = variablesArray.find((item) => item.key.toLowerCase() === key.toLowerCase())
    return variable && variable.value && variable.value.trim() !== ''
  })
  return temp
}

export const replaceVariables = (content, variables) => {
  variables?.forEach((variable) => {
    // const regex = new RegExp(`{${variable.key}}`, 'g')
    const regex = new RegExp(`{{\\s*${variable.key}\\s*}}`, 'g')
    content = content.replace(regex, variable.value)
  })
  console.log('replace content', content)
  return content
}

export const checkMessageSequence = (messages) => {
  if (messages.length === 0) return true

  if (messages.length === 1) return false

  if (messages[0].role !== 'user') return false

  for (let i = 0; i < messages.length; i++) {
    const expectedRole = i % 2 === 0 ? 'user' : 'assistant'
    if (messages[i].role !== expectedRole) return false
  }

  if (messages[messages.length - 1].role === 'user') return false

  return true
}

export const generateNewVersion = (promptVersions) => {
  const latestVersionKey = Object.keys(promptVersions).reduce((a, b) =>
    promptVersions[a] > promptVersions[b] ? a : b,
  )
  const latestVersionValue = promptVersions[latestVersionKey]
  const versionParts = latestVersionValue.toString().split('.').map(Number)

  // Increment version logic
  if (versionParts.length === 1) {
    versionParts.push(0) // Start with 1.0 if only major version exists
  }

  if (versionParts[1] < 9) {
    versionParts[1] += 1
  } else {
    versionParts[0] += 1
    versionParts[1] = 0
  }

  const newVersion = parseFloat(versionParts.join('.'))
  return newVersion
}
