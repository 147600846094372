import React from 'react'
import { Box, Typography, useTheme, Avatar, Stack } from '@mui/material'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import PersonIcon from '@mui/icons-material/Person'

const Message = ({ text, sender }) => {
  const theme = useTheme()

  const renderAssistantMessage = (content) => {
    if (typeof content === 'object' && content !== null) {
      // For objects: Replace all occurrences of \n in string values inside the object
      const formattedContent = JSON.stringify(content, (key, value) => 
        typeof value === 'string' ? value.replace(/\n/g, '\\n') : value, 2);
      
      return (
        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {formattedContent}
        </pre>
      );
    } else if (typeof content === 'string') {
      try {
        // Attempt to parse the string content as JSON
        const parsedJson = JSON.parse(content);
        
        // For parsed JSON, replace \n in string values inside the JSON object
        const formattedJson = JSON.stringify(parsedJson, (key, value) => 
          typeof value === 'string' ? value.replace(/\n/g, '\\n') : value, 2);
        
        return (
          <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {formattedJson}
          </pre>
        );
      } catch (error) {
        // If it's a regular string, replace \n with <br /> and render it
        const formattedContent = content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
        
        return <Typography>{formattedContent}</Typography>;
      }
    } else {
      // For any other type, convert to string and render
      return <Typography>{String(content)}</Typography>;
    }
  };

  return (
    <Stack
      direction={sender === 'user' ? 'row-reverse' : 'row'}
      alignItems="flex-start"
      sx={{
        maxWidth: '100%',
        mb: 1,
      }}
    >
      <Avatar
        sx={{
          bgcolor: sender === 'user' ? theme.palette.primary.main : theme.palette.primary.main,
          mr: sender === 'user' ? 0 : 1,
          ml: sender === 'user' ? 1 : 0,
          width: 28,
          height: 28,
        }}
      >
        {sender === 'user' ? <PersonIcon /> : <SmartToyIcon />}
      </Avatar>
      <Box
        sx={{
          maxWidth: 'calc(100% - 60px)',
          p: 1,
          borderRadius: 1,
          color: sender === 'user' ? theme.palette.primary.icon : theme.palette.secondary.textColor,
          bgcolor: sender === 'user' ? 'primary.light' : 'secondary.light',
          overflowX: 'auto',
          wordWrap: 'break-word',
        }}
      >
        {sender === 'user' ? <Typography>{text}</Typography> : renderAssistantMessage(text)}
      </Box>
    </Stack>
  )
}

export default Message
