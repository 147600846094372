import { AccordionDetails, Box, Container, Stack, TextField, Typography } from '@mui/material'
import {
  Brackets,
  CustomAccordionSummary,
  ExampleText,
  Highlight,
  InstructionText,
  SystemAccordion,
  SystemAccordionDetails,
} from './VariableHelperStyles'
import { SystemMessageBox, SystemTextField } from '../studioChat/studioChatStyles'
import { useTranslation } from 'react-i18next'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledAccordion, StyledAccordionSummary } from '../variables/variablesStyles'
import {
  USEISMOBILE,
  VARIABLE_HELPER_SYSTEM_VALUE,
  VARIABLE_HELPER_VAR_NAME,
  VARIABLE_HELPER_VAR_VALUE,
} from '../../constants/Constants'

const VariableHelper = () => {
  const { ready, t } = useTranslation()

  const isMobile = USEISMOBILE()

  return (
    <Container sx={{ userSelect: 'none' }}>
      <InstructionText variant="body1" paragraph isMobile={isMobile}>
        To get started, simply use curly brackets, like <Brackets>{'{'}</Brackets>
        <Highlight>variableName</Highlight>
        <Brackets>{'}'}</Brackets> to add a new variable. Once you've used a variable in this
        format, it will be captured and can be managed here. You can then reference and modify your
        variables as needed.
      </InstructionText>
      <ExampleText variant="body2" color="textSecondary" paragraph isMobile={isMobile}>
        Example: While typing, if you add some text inside <Brackets>{'{'}</Brackets>
        <Highlight>userName</Highlight>
        <Brackets>{'}'}</Brackets> curly brackets, it will be recognized as a variable.
      </ExampleText>

      <SystemMessageBox>
        <SystemAccordion expanded={true}>
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span> {ready ? t('studio.studioChat.system') : 'SYSTEM'}</span>
            </Box>
          </CustomAccordionSummary>

          <SystemAccordionDetails>
            <Stack direction="column" mt={0}>
              <SystemTextField
                label=""
                variant="outlined"
                value={VARIABLE_HELPER_SYSTEM_VALUE}
                fullWidth
                multiline
                minRows={1}
                maxRows={15}
                disabled={true}
                sx={{
                  userSelect: 'none',
                }}
                InputProps={{
                  sx: {
                    paddingTop: '10px',
                    borderRadius: 2,
                    fontSize: isMobile ? '16px' : '20px',
                    userSelect: 'none',
                  },
                }}
              />
            </Stack>
          </SystemAccordionDetails>
        </SystemAccordion>
      </SystemMessageBox>

      <ExampleText variant="body2" color="textSecondary" paragraph isMobile={isMobile}>
        You can then set its value here, allowing you to dynamically manage and reuse content
        throughout your project. For example:
      </ExampleText>

      <Stack direction="column" spacing={2}>
        <StyledAccordion key="variable" expanded={true}>
          <StyledAccordionSummary>
            <Typography sx={{ fontWeight: 'bold' }}>{VARIABLE_HELPER_VAR_NAME}</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <TextField
              variant="outlined"
              size="small"
              multiline
              minRows={1}
              value={VARIABLE_HELPER_VAR_VALUE}
              fullWidth
              disabled={true}
              InputProps={{
                style: {
                  borderRadius: '8px',
                  userSelect: 'none',
                  fontSize: isMobile ? '16px' : '20px',
                },
              }}
            />
          </AccordionDetails>
        </StyledAccordion>
      </Stack>

      <ExampleText variant="body2" color="textSecondary" paragraph isMobile={isMobile}>
        By setting these values, you can easily update and maintain your variables across your
        entire project.
      </ExampleText>
    </Container>
  )
}

export default VariableHelper
