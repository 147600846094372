import { Divider, Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { CaptionTypography, RedTypography } from './aboutStyles'
import { USEISMOBILE } from '../../../constants/Constants'
import CommonStyles from '../../../assets/styles/CommonStyles'
import { Images } from '../../../assets/image'
import { useTranslation } from 'react-i18next'

const About = () => {
  const isMobile = USEISMOBILE(0)
  const { t } = useTranslation()

  const [loadImage, setLoadImage] = useState(true)
  return (
    <Grid container direction="row" spacing={0}>
      <CommonStyles.LeftGrid xs={12} sm={6}>
        {loadImage && (
          <Skeleton variant="rectangular" animation={'wave'} width={400} height={300} />
        )}
        <CommonStyles.ImageBox
          component="img"
          src={Images.aboutImage}
          alt="Prompt Studio Hero Page"
          draggable={false}
          style={{ width: '100%', padding: '10px', display: loadImage ? 'none' : 'block' }}
          onLoad={() => setLoadImage(false)}
        />
      </CommonStyles.LeftGrid>
      <CommonStyles.RightGrid xs={12} sm={6}>
        {loadImage ? (
          <>
            <Skeleton variant="text" width="60%" />
            <Divider />
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="70%" />
          </>
        ) : (
          <>
            <RedTypography isMobile={isMobile}>{t('landingPage.about.aboutHeading')}</RedTypography>
            <Divider />
            <CaptionTypography isMobile={isMobile}>
              {t('landingPage.about.aboutCaption')}
            </CaptionTypography>
          </>
        )}
      </CommonStyles.RightGrid>
    </Grid>
  )
}

export default About
