import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  styled,
} from '@mui/material'

const RoleButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.studio.userButton,
  color: theme.palette.secondary.main,
}))

const UserBotton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  backgroundColor: theme.palette.studio.runButton,
  color: theme.palette.secondary.main,
}))

const UserPromptBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  mx: 'auto',
  mt: 2,
  border: '2px solid black',
  padding: '15px 15px 15px 15px',
  borderRadius: '20px',
  '&:focus-within': {
    borderColor: theme.palette.primary.main,
  },
  borderColor: theme.palette.textColor,
}))

const UserTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
}))

const MainBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  // zIndex: 1, need for later
  backgroundColor: theme.palette.background.paper,
}))

const VariableAccordian = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 50,
}))

const VariableAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: 0,
}))

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '20px',
  '&.Mui-expanded': {
    minHeight: '20px',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: '10px',
    marginBottom: '10px',
    '&.Mui-expanded': {
      marginTop: '10px',
      marginBottom: '15px',
    },
  },
}))

export {
  RoleButton,
  UserBotton,
  UserPromptBox,
  UserTextField,
  MainBox,
  VariableAccordian,
  VariableAccordionDetails,
  CustomAccordionSummary,
}
