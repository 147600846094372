// src/App.js

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import CustomRouter from './router/Router'
import { darkTheme, lightTheme } from './themes/Theme'
import { ThemeProvider } from '@emotion/react'
import ErrorBoundary from './loggers/ErrorBoundary'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { USEISMOBILE } from './constants/Constants'
import { ToastContainer } from 'react-toastify'
import { AnimatePresence } from 'framer-motion'
import { AuthProvider } from './AuthContext'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'

function App() {
  const isMobile = USEISMOBILE()
  const helmetContext = {}
  //Theme

  // Detect system theme preference
  const isSystemDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const { systemTheme } = useSelector((state) => state.common)
  const Theme =
    systemTheme === 'system'
      ? isSystemDarkMode
        ? darkTheme
        : lightTheme
      : systemTheme === 'dark'
        ? darkTheme
        : lightTheme
  return (
    <ErrorBoundary>
      <AuthProvider>
        <ThemeProvider theme={Theme}>
          <BrowserRouter>
            <HelmetProvider context={helmetContext}>
              <Helmet titleTemplate="PromptStudio | %s" title="" />
              <CustomRouter />
            </HelmetProvider>
            <ToastContainer
              position={isMobile ? 'top-center' : 'top-right'}
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              toastClassName="dark-toast"
            />
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default App
