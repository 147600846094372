import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Tooltip,
  Typography,
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import InfoIcon from '@mui/icons-material/Info'
import { MEMORY_INFO } from '../../../constants/Constants'
import { Label } from '@mui/icons-material'
import { StyledAccordion, StyledAccordionSummary } from '../../variables/variablesStyles'
import { useTheme } from '@emotion/react'
import CommonStyles from '../../../assets/styles/CommonStyles'

const PublishPromptDialog = ({
  publishDialog,
  setPublishDialog,
  handlePublishPrompt,
  variables,
  variableKey,
}) => {
  const { messages } = useSelector((state) => state.conversations)
  const [summarized, setSummarized] = useState(true)
  const [defaultVariables, setDefaultVariables] = useState([])
  const theme = useTheme()

  const initializeVariables = useCallback(() => {
    const initializedVariables = variableKey.map((key) => {
      const existingVariable = variables.find((v) => v.key === key)
      return {
        key,
        value: existingVariable ? existingVariable.value : '',
      }
    })
    setDefaultVariables(initializedVariables)
  }, [variables, variableKey])

  useEffect(() => {
    if (publishDialog) {
      initializeVariables()
    }
  }, [publishDialog, initializeVariables])

  useEffect(() => {
    initializeVariables()
  }, [variableKey, initializeVariables])

  const handlePublishConfirm = (Flag) => {
    // if (messages.length === 0) {
    //   toast.warning('Please add messages')
    //   return
    // }

    setPublishDialog(true)
    handlePublishPrompt(defaultVariables)
  }

  const handleVariableChange = (key, value) => {
    setDefaultVariables((prevVariables) =>
      prevVariables.map((v) => (v.key === key ? { ...v, value } : v)),
    )
  }

  return (
    <Dialog
      open={publishDialog}
      sx={{ padding: 2 }}
      onClose={() => setPublishDialog(false)}
      fullWidth
    >
      <Typography
        sx={{
          position: 'relative',
          maxWidth: '100%',
          margin: 0,
          padding: { xs: '0.5em 0.5em', sm: '.8em 1em 0' }, // Responsive padding
          color: 'inherit',
          fontSize: { xs: '1.5em', sm: '1.875em' }, // Responsive font size
          fontWeight: 600,
          textAlign: 'center',
          textTransform: 'none',
          wordWrap: 'break-word',
        }}
      >
        Confirm Commit
      </Typography>
      <Typography
        variant="h6"
        sx={{
          zIndex: 1,
          justifyContent: 'center',
          margin: 0,
          padding: { xs: '0.5em', sm: '1em 1.6em .3em' }, // Responsive padding
          color: 'inherit',
          fontSize: { xs: '1em', sm: '1.125em' }, // Responsive font size
          fontWeight: 'normal',
          lineHeight: 'normal',
          textAlign: 'center',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        Are you sure you want to commit these changes?
      </Typography>

      {variables.length !== 0 && (
        <>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              maxHeight: '60vh',
            }}
          >
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">Set Default Variables</Typography>

            {defaultVariables.map((newVariable, index) => {
              return (
                <Box key={index} sx={{ marginTop: '8px' }}>
                  <label style={{ paddingLeft: '12px' }}>{newVariable.key}</label>
                  <TextField
                    placeholder={newVariable.key}
                    value={newVariable.value}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    onChange={(e) => handleVariableChange(newVariable.key, e.target.value)}
                  />
                </Box>
              )
            })}
          </DialogContent>
        </>
      )}

      <DialogActions sx={{ justifyContent: 'center', padding: '16px 0px' }}>
        <CommonStyles.PrimaryButton onClick={handlePublishConfirm}>
          Proceed
        </CommonStyles.PrimaryButton>
        <CommonStyles.DangerButton variant="contained" onClick={() => setPublishDialog(false)}>
          Cancel
        </CommonStyles.DangerButton>
      </DialogActions>
    </Dialog>
  )
}

export default PublishPromptDialog
