import { Link, styled, Typography } from '@mui/material'

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.redText,
  fontSize: ['1.5rem', '1.8rem', '2rem'],
  fontWeight: '600',
  lineHeight: '2.5rem',
  letterSpacing: '1px',
  userSelect: 'none',
}))
const EmailLink = styled(Link)(({ theme }) => ({
  color: theme.palette.redText,
  fontWeight: 600,
  paddingTop: '16px', // Padding Y (py) in MUI is the vertical padding
  paddingBottom: '16px', // Padding Y (py) in MUI is the vertical padding
  letterSpacing: '2px',
  textDecoration: 'none',
  userSelect: 'text',
  '&:hover': {
    textDecoration: 'underline',
  },
}))
const GetInTouchTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  letterSpacing: '1px',
  userSelect: 'none',
}))

export { HeadingTypography, EmailLink, GetInTouchTypography }
