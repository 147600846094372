import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid, TextField } from '@mui/material'
import { ModalBox } from './historyModalStyles'

const HistoryModal = ({ title, body, name, handleChangeInput, open, handleClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {title ? title : 'Version History'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {body}
          </Typography>

          <Grid container justifyContent="flex-end">
            <Button variant="contained" sx={{ mt: 2 }}>
              Close
            </Button>
          </Grid>
        </ModalBox>
      </Modal>
    </>
  )
}

export default HistoryModal
