import { Box, Card, Grid, Typography, styled } from '@mui/material'

export const MainGrid = styled(Grid)(() => ({
  padding: ['10px', '20px', '40px'],
  justifyContent: 'center',
  marginTop: 5,
}))

export const MainCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  padding: ['20px', '30px', '40px'],
  boxShadow: '0px 3px 7px 0px rgba(28,31,33,0.15)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  height: '100%',
}))

export const ImageBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
  userSelect: 'none',
}))

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: ['1.5rem', '1.8rem', '2rem'],
  fontWeight: '600',
  lineHeight: '2.5rem',
  letterSpacing: '1px',
  color: theme.palette.landingPage.card.title,
  userSelect: 'none',
}))

export const TypographyContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.landingPage.card.describe,
  lineHeight: '1.5rem',
  fontWeight: '700',
  userSelect: 'none',
}))
