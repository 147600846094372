import { styled, Typography } from '@mui/material'

const HeadingTypography = styled(Typography)(({ isMobile, theme }) => ({
  color: theme.palette.landingPage.aboutUs.mainTitle,
  fontWeight: 600,
  fontSize: isMobile ? '25px' : '50px',
}))
const CaptionTypography = styled(Typography)(({ isMobile, theme }) => ({
  color: theme.palette.landingPage.aboutUs.title,
  lineHeight: '1.5rem',
  fontWeight: '700',
  fontSize: isMobile ? '1rem' : '1.4rem',
  marginTop: isMobile ? '15px' : '',
  textAlign: 'left',
  userSelect: 'none',
  paddingTop: '24px',
}))
const RedTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.landingPage.aboutUs.describe,
  fontSize: ['1.5rem', '1.8rem', '2rem'],
  fontWeight: '600',
  lineHeight: '2.5rem',
  letterSpacing: '1px',
  userSelect: 'none',
}))

export { HeadingTypography, CaptionTypography, RedTypography }
