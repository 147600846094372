import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Grid, TextField, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ModalBox } from './AddItemModalStyles'
import CommonStyles from '../../../assets/styles/CommonStyles'

const AddItemModal = ({
  title,
  body,
  name,
  handleChangeInput,
  open,
  handleClose,
  handleSubmit,
  validateInput,
  setError,
  error,
  loading,
  setLoading,
}) => {
  const textFieldRef = useRef(null)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (open) {
      if (textFieldRef.current) {
        textFieldRef.current.focus()
        textFieldRef.current.value = ''
      }
      setShowError(false)
      setError('')
    }
  }, [open])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      validateAndSubmit()
    }
  }

  const handleChange = (event) => {
    handleChangeInput(event)
    if (showError) {
      validateInput(event.target.value)
    }
  }

  const validateAndSubmit = () => {
    setShowError(true)
    if (validateInput(name)) {
      handleSubmit()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-title">
        {title}
        <CommonStyles.CloseIconButton onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Typography id="dialog-description" sx={{ mt: 2 }}>
          {body}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="outlined-basic"
            value={name}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            inputRef={textFieldRef}
            onKeyPress={handleKeyPress}
            error={!!error && showError}
            helperText={showError ? error : ''}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <CommonStyles.PrimaryButton onClick={validateAndSubmit} disabled={loading}>
          Create
        </CommonStyles.PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddItemModal
