import React, { useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import TopBar from '../../components/TopBar'
import { MainBox, StyledBox } from './StudioLayoutStyles'
import { Box, CircularProgress, Stack } from '@mui/material'
import { AI_MODELS, MODEL_OPTIONS, USEISMENU, USEISMOBILE } from '../../constants/Constants'
import { getPromptDetails } from '../../redux/action/PlaygroundAction'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  setChatSummary,
  setIsPublished,
  setMessagesInitial,
  setSelectedModel,
  setSystemValue,
} from '../../redux/slice/Conversation'
import { useAuth } from '../../AuthContext'
import StudioDrawer from '../../components/studioDrawer'
import StudioBody from '../../components/studioBody'
import WelcomeDialog from '../../components/dialog/welcomeDialog'
import StudioParams from '../../components/studioParams'

const StudioLayout = () => {
  const [rightSidebar, setRightSidebar] = useState(false)
  const [rightSidebarCompare, setRightSidebarCompare] = useState(false)
  const [studioDrawerOpen, setStudioDrawerOpen] = useState(false)
  const [selectedModelOptionsCompare, setSelectedModelOptionsCompare] = useState(
    MODEL_OPTIONS[AI_MODELS[0]],
  )
  const [selectedCategoryCompare, setSelectedCategoryCompare] = useState(
    MODEL_OPTIONS[AI_MODELS[0]][0],
  )
  const [selectedModelOptions, setSelectedModelOptions] = useState(MODEL_OPTIONS[AI_MODELS[0]])
  const [selectedCategory, setSelectedCategory] = useState(MODEL_OPTIONS[AI_MODELS[0]][0])

  const { isLoading, isPublished } = useSelector((state) => state.conversations)
  const { dialogOpen } = useSelector((state) => state.common)
  const { selectedProjectId, selectedPrompt } = useSelector((state) => state.UserProjects)

  const dispatch = useDispatch()
  const isMobile = USEISMOBILE()
  const isMenu = USEISMENU()
  const { userInfo } = useAuth()

  const toggleDrawer = (val) => {
    setStudioDrawerOpen(val)
  }


  const handleRightSidebar = () => {
    setRightSidebar((prev) => !prev)
    if (rightSidebarCompare) {
      setRightSidebarCompare(false)
    }
  }

  const handleRightSidebarCompare = () => {
    setRightSidebarCompare((prev) => !prev)
    if (rightSidebar) {
      setRightSidebar(false)
    }
  }


  return (
    <MainBox>
      <CssBaseline />
      <TopBar toggleDrawer={toggleDrawer} />

      {/* {!isMenu && ( */}
      <Box sx={{ zIndex: 0 }}>
        <StudioDrawer studioDrawerOpen={studioDrawerOpen} toggleDrawer={toggleDrawer} />
      </Box>
      {/* )} */}

      <StyledBox component="main">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <StudioBody
            handleRightSidebar={handleRightSidebar}
            handleRightSidebarCompare={handleRightSidebarCompare}
            selectedModelOptionsCompare={selectedModelOptionsCompare}
            setSelectedModelOptionsCompare={setSelectedModelOptionsCompare}
            selectedCategoryCompare={selectedCategoryCompare}
            setSelectedCategoryCompare={setSelectedCategoryCompare}
            selectedModelOptions={selectedModelOptions}
            setSelectedModelOptions={setSelectedModelOptions}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </StyledBox>

      <StudioParams
        component={1}
        openParam={rightSidebar}
        onClose={handleRightSidebar}
        category={selectedCategory}
      />
      <StudioParams
        component={2}
        openParam={rightSidebarCompare}
        onClose={handleRightSidebarCompare}
        category={selectedCategoryCompare}
      />

      {dialogOpen && <WelcomeDialog />}
    </MainBox>
  )
}

export default StudioLayout
