import { Box, Container, Stack } from '@mui/material'
import React from 'react'
import { EmailLink, GetInTouchTypography, HeadingTypography } from './ContactUsStyles'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Stack spacing={4} marginTop={{ xs: '20px', sm: '30px' }} paddingX={'10px'}>
        <Box>
          <HeadingTypography>{t('landingPage.contact.contactUs')}</HeadingTypography>
          <GetInTouchTypography
            fontSize={{ xs: '1.2rem', sm: '1.5rem', md: '2rem', lg: '2.5rem' }}
            mb={{ xs: 3, sm: 4, md: 5 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{t('landingPage.contact.getInTouch')} :</span>

              <EmailLink
                href="mailto:contact@promptstudio.dev"
                fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.5rem' }}
              >
                contact@promptstudio.dev
              </EmailLink>
            </Stack>
          </GetInTouchTypography>
        </Box>
      </Stack>
    </Container>
  )
}

export default ContactUs
