import { BorderColor } from '@mui/icons-material'
import { Box, Button, styled, Typography } from '@mui/material'

const MainBox = styled(Box)(() => ({
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflowX: 'hidden',
}))
const LeftBox = styled(Box)(() => ({
  // order: isTab ? 2 : 1,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // width: ['100%', '50%'],
  // width: isTab ? '100%' : '50%',
  paddingLeft: '16px',
  paddingRight: '16px',
  marginTop: '16px',
  boxSizing: 'border-box',
}))
const RightBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
}))
const TextBox = styled(Box)(() => ({
  // textAlign: { xs: 'center', md: 'center' },
  maxWidth: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
}))

const ImageBox = styled(Box)({
  marginTop: '48px',
  marginRight: '24px',
  padding: '32px',
  userSelect: 'none',
})

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.landingPage.hero.title,
  fontWeight: 600,
  fontSize: '2rem',
  userSelect: 'none',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
    alignItems: 'right',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.5rem',
  },
  flexWrap: 'wrap',
}))

const CaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.landingPage.hero.describe,
  fontWeight: 600,
  fontSize: '1rem',
  userSelect: 'none',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.3rem',
  },
  flexWrap: 'wrap',
}))

const GetStartedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.landingPage.hero.button,
  padding: '20px 30px 20px 30px',
  borderRadius: 50,
  color: theme.palette.white,
  marginTop: '40px',
  zIndex: 1099,
  '&:hover': {
    backgroundColor: theme.palette.white,
    color: theme.palette.landingPage.hero.title,
    border: '2px solid',
    BorderColor: theme.palette.landingPage.hero.button,
  },
})) // You can add responsive styles here as needed

export {
  HeadingTypography,
  CaptionTypography,
  GetStartedButton,
  MainBox,
  LeftBox,
  RightBox,
  TextBox,
  ImageBox,
}
