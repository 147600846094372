import { Stack, styled, TextField, Typography } from '@mui/material'
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton'
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem'

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
}
const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}
const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    cursor: pointer;
    &:hover {
       background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }
    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
    `,
)

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
    &:last-of-type {
      border-bottom: none;
    }
    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
    `,
)
const Listbox = styled('ul')(
  ({ theme }) => `
font-family: 'IBM Plex Sans', sans-serif;
font-size: 0.875rem;
box-sizing: border-box;
padding: 6px;
margin: 25px 0;
min-width: 200px;
border-radius: 12px;
outline: 0px;
background: ${theme.palette.mode === 'dark' ? grey[900] : theme.palette.white};
border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};

max-width: 280px;
z-index: 999;

.closed & {
  opacity: 0;
  transform: scale(0.95, 0.8);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }
    .placement-top & {
      transform-origin: bottom;
    }
    .placement-bottom & {
      transform-origin: top;
    }
    `,
)
const ProjectButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.textColor,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '200px',
  fontWeight: 'bold',
  fontSize: '150%',
  userSelect: 'none',
}))

const SearchTextField = styled(TextField)(() => ({
  //   height: '100px',
  width: '100%',
}))

const ProjectItemText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '160px',
  cursor: 'pointer',
}))

const ProjectsStack = styled(Stack)(() => ({
  maxHeight: '280px',
  overflow: 'auto',
}))

export {
  MenuButton,
  MenuItem,
  Listbox,
  ProjectButton,
  SearchTextField,
  ProjectItemText,
  ProjectsStack,
}
