import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Dropdown } from '@mui/base/Dropdown'
import { Menu } from '@mui/base/Menu'
import { CssTransition } from '@mui/base/Transitions'
import { PopupContext } from '@mui/base/Unstable_Popup'
import { Box, Divider, Stack, THEME_ID, Typography } from '@mui/material'

import {
  Listbox,
  MenuButton,
  MenuItem,
  ProjectButton,
  ProjectItemText,
  ProjectsStack,
  SearchTextField,
} from './ProjectMenuStyles'

import { FolderOutlined } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import AddItemModal from '../modal/addItemModal'
import RenameModal from '../modal/renameModal'

import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../AuthContext'

import {
  addProject,
  setSelectedProject,
  setUserProjects,
  setSelectedProjectId,
  setEditProjectName,
  setSelectedPrompt,
  setOpenFolderId,
  setSelectedFolderId,
  setSelectedFolderName,
  setProjectsData,
  setPromptVersions,
} from '../../redux/slice/UserProjects'
import {
  createProject,
  deactivateProject,
  getProjectDetails,
  getUserProjects,
  updateProjectName,
} from '../../redux/action/UserProjectsAction'

import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useTheme } from '@mui/material'
import { AI_MODELS, NAMING_MAX, NAMING_MIN } from '../../constants/Constants'
import {
  setMessagesCompareInitial,
  setMessagesInitial,
  setSelectedModel,
  setSelectedModelCompare,
} from '../../redux/slice/Conversation'

const ProjectMenu = () => {
  const {
    projects,
    selectedProject,
    userProjects,
    editProjectName,
    selectedProjectId,
    openFolderId,
    projectsData,
  } = useSelector((state) => state.UserProjects)

  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const Theme = useTheme()
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [renameText, setRenameText] = useState('')
  const [projectName, setProjectName] = useState('')
  const [projectModalOpen, setProjectModalOpen] = useState(false)
  const [editProjectId, setEditProjectId] = useState('')
  const [deleteProjectId, setDeleteProjectId] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const [addLoading, setAddLoading] = useState(false)
  const [renameLoading, setRenameLoading] = useState(false)

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value || '')
  }

  const [error, setError] = useState('')
  const validateInput = (value) => {
    if (!value.trim()) {
      setError('Name is required')
      return false
    }
    if (value.trim().length < NAMING_MIN) {
      setError(`Name must be at least ${NAMING_MIN} characters long`)
      return false
    }
    if (value.trim().length > NAMING_MAX) {
      setError(`Name must be less than ${NAMING_MAX} characters`)
      return false
    }
    if (/^\d/.test(value.trim())) {
      setError('Name cannot start with a number')
      return false
    }
    setError('')
    return true
  }

  // Filter the projects based on the searchQuery
  const filteredProjects = Object.fromEntries(
    Object.entries(userProjects).filter(([key, value]) => {
      if (typeof value !== 'string') {
        console.warn(`Unexpected non-string value for project ${key}:`, value)
        return false
      }
      return value.toLowerCase().includes((searchQuery || '').toLowerCase())
    }),
  )

  const handleAddProject = (name, userId) => {
    setAddLoading(true)
    try {
      dispatch(
        createProject({
          name,
          userId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.detail)
              handleCloseProjectModal()
              setProjectName('')
              setRenameText('')
              dispatch(setSelectedModel(AI_MODELS[0]))
              dispatch(setSelectedModelCompare(AI_MODELS[0]))
              dispatch(setPromptVersions({}))
              fetchUserProjects(userId, true)
              setAddLoading(false)
            },
            onError: (error) => {
              console.error('Deleting failed:', error)
              toast.warning(error.data.detail)
              setAddLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Deleting:', error)
    }
    // dispatch(addProject({ projectName: projectName }))
  }

  const handleDeleteClick = (event, projectId) => {
    event.stopPropagation()
    const userId = userInfo?.userId
    try {
      dispatch(
        deactivateProject({
          projectId,
          userId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.detail)
              fetchUserProjects(userInfo?.userId)
            },
            onError: (error) => {
              console.error('Deleting failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Delete:', error)
    }
  }

  const handleRenameSubmit = () => {
    setRenameLoading(true)
    const name = renameText
    const userId = userInfo?.userId
    const projectId = editProjectId
    try {
      dispatch(
        updateProjectName({
          name,
          userId,
          projectId,
          meta: {
            onSuccess: (data) => {
              toast.success(data.detail)
              fetchUserProjects(userInfo?.userId)
              handleCloseRenameModal()
              if (selectedProjectId === editProjectId) {
                dispatch(setSelectedProject(renameText))
              }
              setRenameText('')
              setProjectName('')
              setRenameLoading(false)
            },
            onError: (error) => {
              console.error('Updating failed:', error)
              toast.warning(error.data.detail)
              setRenameLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during update:', error)
    }
  }

  const fetchUserProjects = (userId, isCreated = false) => {
    try {
      dispatch(
        getUserProjects({
          userId,
          meta: {
            onSuccess: (data) => {
              dispatch(setUserProjects(data?.data))
              const projectIds = Object.keys(data?.data)
              const lastProjectId = projectIds[projectIds.length - 1]
              const lastProjectName = data?.data[lastProjectId]
              if (isCreated) {
                dispatch(setSelectedProjectId(lastProjectId))
                dispatch(setSelectedProject(lastProjectName))
              }
              if (!projectIds.find((projectId) => projectId === selectedProjectId)) {
                dispatch(setSelectedProjectId(lastProjectId))
                dispatch(setSelectedProject(lastProjectName))
              }
            },
            onError: (error) => {
              console.error('Fetching failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
    }
  }

  const AutoSelectLastPromptFolder = (temp) => {
    dispatch(setMessagesInitial([]))
    dispatch(setMessagesCompareInitial([]))
    const folders = temp.folders || []
    const lastFolder = folders[folders.length - 1]
    const lastPrompt = lastFolder?.prompts?.[lastFolder.prompts.length - 1]

    if (lastPrompt && lastFolder) {
      const newSelectedPrompt = {
        promptId: lastPrompt._id,
        promptName: lastPrompt.promptName,
        promptFolderId: lastFolder._id,
        promptFolderName: lastFolder.folderName,
      }

      dispatch(setSelectedPrompt(newSelectedPrompt))
      dispatch(setSelectedFolderId(lastFolder._id))
      dispatch(setSelectedFolderName(lastFolder.folderName))
      dispatch(setOpenFolderId(lastFolder._id))
    }
  }

  const fetchProjectDetails = (userId, projectId, projectName) => {
    try {
      dispatch(
        getProjectDetails({
          userId,
          projectId,
          meta: {
            onSuccess: (data) => {
              const temp = data?.data?.project
              dispatch(setProjectsData(temp))
              if (selectedProjectId === '' && selectedProject === '') {
                dispatch(setSelectedProject(projectName))
                dispatch(setSelectedProjectId(projectId))
              } else {
              }

              AutoSelectLastPromptFolder(temp)

              if (openFolderId === null) {
                dispatch(setOpenFolderId(temp?.folders[0]?._id))
              }
            },
            onError: (error) => {
              console.error('Fetching failed:', error)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
    }
  }

  const handleDeleteConfirmation = (event, id, projectName) => {
    event.stopPropagation()
    Swal.fire({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete  project "${projectName}" ? This action will permanently remove the project and all associated data`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClick(event, id)
      } else {
        // example codes to clear up
      }
    })
  }

  const handleClick = (p) => {
    fetchProjectDetails(userInfo?.userId, p, userProjects[p])
    dispatch(setSelectedProject(userProjects[p]))
    dispatch(setSelectedProjectId(p))
    // dispatch(setSelectedPrompt({}))
  }
  const handleCloseProjectModal = () => setProjectModalOpen(false)
  const handleCloseRenameModal = () => setRenameModalOpen(false)

  const handleProjectChangeInput = (e) => {
    setProjectName(e.target.value)
  }
  const handleEditClick = (id, name) => {
    setEditProjectId(id)
    // setProjectName(name)
    setRenameText(name)
    setRenameModalOpen(true)
  }
  const handleEditNameInput = (e) => {
    setRenameText(e.target.value)
  }

  const handleOpenProjectModal = () => setProjectModalOpen(true)
  const handleProjectSubmit = () => {
    handleAddProject(projectName, userInfo?.userId)
  }

  useEffect(() => {
    if (userInfo?.userId) {
      fetchUserProjects(userInfo?.userId)
    }
  }, [JSON.stringify(userInfo)])

  useEffect(() => {
    if (userInfo?.userId) { 
      fetchProjectDetails(userInfo?.userId, selectedProjectId, selectedProject)
    }
  }, [selectedProjectId, JSON.stringify(userInfo)])

  return (
    <>
      <Dropdown>
        <MenuButton>
          <ProjectButton variant="h6" noWrap component="div">
            /{selectedProject}
            {/* PromptStudio.dev */}
          </ProjectButton>
        </MenuButton>

        <Menu slots={{ listbox: AnimatedListbox }}>
          {/* <Stack alignItems={'center'}>
            <SearchTextField
              placeholder="Search..."
              onChange={handleSearchQuery}
              InputProps={{
                sx: {
                  marginBottom: '10px',
                  borderRadius: 2,
                  height: '40px',
                },
              }}
            />
          </Stack> */}
          <ProjectsStack sx={{ padding: 0.2, width: '240px' }}>
            {Object.keys(filteredProjects)?.map((projectId) => (
              <MenuItem key={projectId} onClick={() => handleClick(projectId)}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Stack direction={'row'} flex={1}>
                    <FolderOutlined fontSize="small" />
                    <ProjectItemText marginLeft={1}>{userProjects[projectId]}</ProjectItemText>
                  </Stack>
                  <Box>
                    <EditIcon
                      onClick={(event) => {
                        event.stopPropagation()
                        handleEditClick(projectId, userProjects[projectId])
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                    {Object.keys(userProjects).length > 1 && (
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation()
                          handleDeleteConfirmation(event, projectId, userProjects[projectId])
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </Box>
                </Stack>
              </MenuItem>
            ))}
          </ProjectsStack>

          <Divider />
          <MenuItem onClick={handleOpenProjectModal}>
            <Stack direction={'row'} alignItems={'center'}>
              <Add fontSize="small" />
              <Typography marginLeft={1}>Add Project</Typography>
            </Stack>
          </MenuItem>
        </Menu>
        <AddItemModal
          title="Create a new project"
          body="Projects are shared environments where teams can collaborate and share API resources. You can set custom rate limits and manage access to resources."
          name={projectName}
          handleChangeInput={handleProjectChangeInput}
          open={projectModalOpen}
          setOpen={setProjectModalOpen}
          handleClose={handleCloseProjectModal}
          handleSubmit={handleProjectSubmit}
          validateInput={validateInput}
          loading={addLoading}
          setLoading={setAddLoading}
          error={error}
          setError={setError}
        />
        <RenameModal
          name={renameText}
          open={renameModalOpen}
          handleClose={handleCloseRenameModal}
          handleChangeInput={handleEditNameInput}
          handleSubmit={handleRenameSubmit}
          loading={renameLoading}
          setLoading={setRenameLoading}
          validateInput={validateInput}
          error={error}
          setError={setError}
        />
      </Dropdown>
    </>
  )
}

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props
  const popupContext = React.useContext(PopupContext)

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    )
  }

  const verticalPlacement = popupContext.placement.split('-')[0]
  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  )
})
AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
}

export default ProjectMenu
