import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, MenuItem } from '@mui/material'
import { useTheme } from '@mui/material'
import { FlagBox, FlagBoxButton, StyledMenu } from './languageSwitcherStyles'
import { LANGUAGES } from '../../constants/Constants'
import LoaderBackdrop from '../loaderBackdrop'

function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeLanguage = (lng) => {
    setLoading(true)
    i18n.changeLanguage(lng).then(() => {
      setLoading(false)
    })
    handleClose()
  }

  const currentLanguage = i18n.language ? i18n.language.split('-')[0] : 'defaultLanguage'
  const currentLangObj = LANGUAGES?.find((lang) => lang.code === currentLanguage) || LANGUAGES[0]
  const CurrentFlag = currentLangObj.flag

  return (
    <Box marginLeft={2}>
      <Button
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={
          <FlagBoxButton>
            <CurrentFlag title={currentLanguage} style={{ width: 24, height: 24 }} />
          </FlagBoxButton>
        }
        variant="text"
        sx={{ fontSize: '19px', color: theme.palette.textColor }}
      >
        {currentLangObj.name}
      </Button>
      <StyledMenu id="language-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {LANGUAGES?.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleChangeLanguage(lang.code)}>
            <FlagBox>
              <lang.flag title={lang.name} style={{ width: 24, height: 24 }} />
            </FlagBox>
            {lang.label}
          </MenuItem>
        ))}
      </StyledMenu>
      <LoaderBackdrop loading={loading} />
    </Box>
  )
}

export default LanguageSwitcher
