import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  styled,
  Typography,
} from '@mui/material'

// Styled components for better visual appeal
const Highlight = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const Brackets = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const InstructionText = styled(Typography)(({ theme, isMobile }) => ({
  fontSize: isMobile ? '16px' : '20px',
  margin: theme.spacing(2, 0),
}))

const ExampleText = styled(Typography)(({ theme, isMobile }) => ({
  fontSize: isMobile ? '16px' : '20px',
  margin: theme.spacing(2, 0),
}))

const Container = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  textAlign: 'center',
}))

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '20px',
  '&.Mui-expanded': {
    minHeight: '20px',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: '10px',
    marginBottom: '10px',
    '&.Mui-expanded': {
      marginTop: '10px',
      marginBottom: '15px',
    },
  },
}))

const SystemAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 50,
}))

const SystemAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: 0,
}))

export {
  Highlight,
  Brackets,
  InstructionText,
  ExampleText,
  Container,
  SystemAccordion,
  SystemAccordionDetails,
  CustomAccordionSummary,
}
