import { Box, styled, Typography } from '@mui/material'

const MessageBox = styled(Box)(({ theme, hovering, deleteHover }) => ({
  display: 'flex',
  // flexDirection: assistant ? 'row' : 'row-reverse',
  alignItems: 'flex-start',
  background: '',
  width: '100%',
  border: '1px solid transparent',
  padding: '10px 20px 10px 20px',
  backgroundColor: theme.palette.secondary.white,
  color: theme.palette.primary.light,
  margin: '10px',
  // border: '2px solid #d3d5e0',
  borderRadius: 20,
  '&:hover': {
    border: deleteHover ? `2px solid red` : `2px solid ${theme.palette.primary.main}`,
  },
  '& img': {
    borderRadius: '50%',
    width: 40,
    height: 40,
    margin: theme.spacing(0, 1),
  },
}))

const NameTypography = styled(Typography)({
  fontWeight: 'bold',
  userSelect: 'none',
})

const MessageTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.textColor,
}))

export { MessageBox, NameTypography, MessageTypography }
