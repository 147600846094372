import { Box, styled } from '@mui/material'
import { Button } from 'react-scroll'

const IconBox = styled(Box)(({ written }) => ({
  cursor: written ? 'not-allowed' : 'pointer',
  marginBottom: '20px',
}))

const ToggleButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: 6,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
  color: disabled ? theme.palette.action.disabled : theme.palette.secondary.main,
  cursor: disabled ? 'not-allowed' : 'pointer',
  border: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette.primary.dark,
  },
}))

const CopyButton = styled(Button)(({ theme, disabled }) => ({
  borderRadius: 6,
  backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
  color: disabled ? theme.palette.action.disabled : theme.palette.secondary.main,
  cursor: disabled ? 'not-allowed' : 'pointer',
  border: 'none',
  boxShadow: 'none',
  padding: '6px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette.primary.dark,
  },
}))

export { IconBox, ToggleButton, CopyButton }
