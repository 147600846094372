import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CommonStyles from '../../../assets/styles/CommonStyles'
import {
  DrawerBox,
  DrawerList,
  DrawerListItem,
  DrawerListItemTypography,
  LogoGrid,
  MainGrid,
  NavItem,
  Overlay,
  StyledIconButton,
  StyledSwipeableDrawer,
  MasterTypography,
} from './TopBarStyles'
import { PAGES_ITEMS, USEISMENU, USEISMOBILE } from '../../../constants/Constants'
import { Grid, ListItemText, useTheme, CircularProgress, Box, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { setPage } from '../../../redux/slice/TopBar'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../languageSwitcher'
import SystemTheme from '../../systemTheme'
import ProfileMenu from '../../profileMenu'

const TopBar = ({ scrollToSection }) => {
  const isMenu = USEISMENU()
  const isMobile = USEISMOBILE()

  const { isLogged } = useSelector((state) => state.common)
  const { t, ready } = useTranslation()
  const theme = useTheme()

  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open)
  }

  const token = localStorage.getItem('token')

  const pages = PAGES_ITEMS(t, ready)

  const handleClick = (page) => {
    const refMap = {
      Home: 'hero',
      Features: 'feature',
      'Prompt Development': 'card',
      'About Us': 'about',
      'Contact Us': 'contact',
    }

    scrollToSection(refMap[page])
    dispatch(setPage(page))
    toggleDrawer(false)
  }

  const drawerContent = (
    <DrawerBox role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <StyledIconButton onClick={toggleDrawer(false)}>
        <CloseIcon />
      </StyledIconButton>
      <DrawerList>
        {pages?.map((page) => (
          <DrawerListItem key={page} component="div" onClick={() => handleClick(page)}>
            <ListItemText
              sx={{ '& h6': { textAlign: 'left', paddingLeft: '20px' } }}
              primary={<DrawerListItemTypography variant="h6">{page}</DrawerListItemTypography>}
            />
          </DrawerListItem>
        ))}
        {isMobile && (
          <Stack direction={'row'} alignSelf={'flex-start'} flexGrow={1}>
            <LanguageSwitcher />
            <SystemTheme />
          </Stack>
        )}
      </DrawerList>
    </DrawerBox>
  )

  return (
    <>
      <MainGrid container direction="row" alignItems="center" justifyContent="space-between">
        <LogoGrid item xs={4}>
          {theme.palette.mode === 'light' ? (
            <CommonStyles.CompanyMainLogo />
          ) : (
            <CommonStyles.CompanyLogoWhite />
          )}
          <MasterTypography isMobile={isMobile}>Promptstudio.dev</MasterTypography>
        </LogoGrid>
        <Grid item xs={8} container justifyContent="flex-end" alignItems="center">
          {isMenu ? (
            <>
              {!isMobile && (
                <>
                  <LanguageSwitcher />
                  <SystemTheme />
                </>
              )}
              {isLogged && token !== null && <ProfileMenu Logout={true} Workspace={true} />}
              <StyledIconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </StyledIconButton>
            </>
          ) : (
            <Grid container direction="row" justifyContent="flex-end" spacing={5}>
              {pages.map((page) => (
                <Grid item key={page} onClick={() => handleClick(page)}>
                  <NavItem sx={{ color: theme.palette.textColor }}>{t(page)}</NavItem>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </MainGrid>
      <StyledSwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {drawerContent}
      </StyledSwipeableDrawer>

      <Overlay open={drawerOpen} onClick={toggleDrawer(false)} />
      <Box sx={{ display: isMenu ? 'none' : 'flex' }}>
        <LanguageSwitcher />
        <SystemTheme />
        {isLogged && token !== null && <ProfileMenu Logout={true} Workspace={true} />}
      </Box>
    </>
  )
}

export default TopBar
