import { Box, Drawer, styled, Typography } from '@mui/material'
import { RIGHT_DRAWER_WIDTH } from '../../constants/Constants'

const ParameterDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    height: '75%',
    borderRadius: '24px',
    top: 100,
    right: 30,
    paddingTop: 0,

    width: RIGHT_DRAWER_WIDTH,
    padding: '10px',
    paddingRight: '2px',
    flexShrink: 0,
    overflow: 'hidden',
    zIndex: 0,
    [`& .MuiDrawer-paper`]: {
      width: RIGHT_DRAWER_WIDTH,
      boxSizing: 'border-box',
    },
  },
}))

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  alignItems: 'center',
  paddingTop: '32px',
  paddingBottom: '32px',
  paddingLeft: '24px',
  paddingRight: '24px',
  zIndex: -10,
  overflow: 'auto',
})

const Heading = styled(Typography)({
  marginBottom: '20px',
  fontWeight: 500,
  fontSize: 40,
})

const MemoryHeading = styled(Typography)({
  marginBottom: '6px',
  fontWeight: 500,
  fontSize: 20,
})

export { ParameterDrawer, StyledBox, Heading, MemoryHeading }
