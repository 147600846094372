import React from 'react'
import { useRoutes } from 'react-router-dom'
import { navigationRoutes } from './Navigation'
import UserRouteConfig from './UserRouteConfig'
import { motion } from 'framer-motion'
import { PAGETRANSITION } from '../constants/Constants'
import LandingLayout from '../layout/landingLayout'
import PageNotFound from '../pages/notFound'
import { element } from 'prop-types'
import UnderMaintenance from '../pages/underMaintance'

const Router = () => {
  const commonRoutes = [
    {
      path: navigationRoutes.main.landingPage,
      element: (
        <motion.div {...PAGETRANSITION}>
          <LandingLayout />
        </motion.div>
      ),
    },
    {
      path: '*',
      element: <PageNotFound />,
      title: 'NotFound',
    },
    {
      path: '/underMaintenance',
      element: <UnderMaintenance />,
    },
  ]

  const routes = [...commonRoutes, ...UserRouteConfig]

  return useRoutes(routes)
}

export default Router
