import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Divider, Menu, MenuItem } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { useAuth } from '../../AuthContext'
import CommonStyles from '../../assets/styles/CommonStyles'
import Profile from '../../pages/profile'
import SingleDialog from '../dialog/singleDialog'
import { DropdownButton, StyledMenu, MenuItemBox, StyledDivider } from './ProfileMenuStyles'
import { navigationRoutes } from '../../router/Navigation'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setInitialProject } from '../../redux/slice/UserProjects'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { setLogIn } from '../../redux/slice/common'

const ProfileMenu = ({ Settings, Workspace, Logout }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { userInfo, logout } = useAuth()
  const { t, ready } = useTranslation()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(!open)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleSettingsClick = () => {
    handleClose()
    navigate(navigationRoutes.user.settings)
  }

  const handleWorkSpaceClick = () => {
    handleClose()
    navigate(navigationRoutes.user.workspace)
  }

  const handleLogoutClick = () => {
    handleClose()
    dispatch(setInitialProject())
    dispatch(setLogIn(false))
    logout()
    navigate(navigationRoutes.main.landingPage)
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <DropdownButton onClick={handleMenuOpen}>
        {userInfo ? (
          <AccountCircleIcon fontSize="large" sx={{ color: theme.palette.textColor }} />
        ) : (
          <AccountCircleIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
        )}
      </DropdownButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          mt: '10px',
          borderRadius: '12px',
          boxShadow: `0px 4px 30px ${theme.palette.divider}`,
        }}
      >
        {/* Profile always visible */}
        <MenuItem onClick={handleClickOpen}>
          <MenuItemBox>
            <PersonIcon fontSize="small" sx={{ mr: 1 }} />
            {t('studio.profileMenu.profile')}
          </MenuItemBox>
        </MenuItem>

        {/* Conditionally render Settings option based on the Settings prop */}
        {Settings && (
          <MenuItem onClick={handleSettingsClick}>
            <MenuItemBox>
              <SettingsIcon fontSize="small" sx={{ mr: 1 }} />
              {t('studio.profileMenu.settings')}
            </MenuItemBox>
          </MenuItem>
        )}

        {/* Conditionally render Workspace option based on the Workspace prop */}
        {Workspace && (
          <MenuItem onClick={handleWorkSpaceClick}>
            <MenuItemBox>
              <ChatBubbleIcon fontSize="small" sx={{ mr: 1 }} />
              {t('studio.profileMenu.workspace')}
            </MenuItemBox>
          </MenuItem>
        )}

        <StyledDivider />

        {/* Conditionally render Logout option based on the Logout prop */}
        {Logout && (
          <MenuItem onClick={handleLogoutClick}>
            <MenuItemBox>
              <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
              {t('studio.profileMenu.logout')}
            </MenuItemBox>
          </MenuItem>
        )}
      </StyledMenu>

      <SingleDialog
        DialogContentComponent={<Profile />}
        handleClose={handleCloseDialog}
        open={open}
      />
    </>
  )
}

ProfileMenu.propTypes = {
  Settings: PropTypes.bool,
  Workspace: PropTypes.bool,
  Logout: PropTypes.bool,
}

export default ProfileMenu
