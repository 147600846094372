import React, { useState } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto'

import { useDispatch, useSelector } from 'react-redux'
import { setSystemTheme } from '../../redux/slice/common'
import {
  StyledBrightnessAutoIcon,
  StyledDarkModeIcon,
  StyledLightModeIcon,
  StyledMenu,
  ThemeButton,
} from './systemThemeStyles'
import { USEISMOBILE } from '../../constants/Constants'

const SystemTheme = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { systemTheme } = useSelector((state) => state.common)
  const dispatch = useDispatch()
  const isMobile = USEISMOBILE()

  const handleMenuClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (theme, event) => {
    event.stopPropagation()
    dispatch(setSystemTheme(theme))
    setAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ThemeButton
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e)}
        isMobile={isMobile}
        variant="outlined"
      >
        {systemTheme === 'light' ? (
          <StyledLightModeIcon />
        ) : systemTheme === 'dark' ? (
          <StyledDarkModeIcon />
        ) : (
          <StyledBrightnessAutoIcon />
        )}
      </ThemeButton>
      <StyledMenu
        id="theme-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(event) => handleClose('light', event)}>
          <ListItemIcon>
            <LightModeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Light"
            primaryTypographyProps={{
              fontSize: '1.1rem',
              fontWeight: 500,
            }}
          />
        </MenuItem>
        <MenuItem onClick={(event) => handleClose('dark', event)}>
          <ListItemIcon>
            <DarkModeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dark"
            primaryTypographyProps={{
              fontSize: '1.1rem',
              fontWeight: 500,
            }}
          />
        </MenuItem>
        <MenuItem onClick={(event) => handleClose('system', event)}>
          <ListItemIcon>
            <BrightnessAutoIcon />
          </ListItemIcon>
          <ListItemText
            primary="System"
            primaryTypographyProps={{
              fontSize: '1.1rem',
              fontWeight: 500,
            }}
          />
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default SystemTheme
