// DatabaseConfig.js
import React from 'react';
import { Typography, TextField, Box, Button, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DatabaseConfig = () => {
  const theme = useTheme();

  return (
    <Box padding={3}>
      <Typography variant="h4" style={{ color: theme.palette.primary.main, marginBottom: '1rem' }}>
        Database Configuration
      </Typography>
      <Paper elevation={3} style={{ padding: '1rem', backgroundColor: theme.palette.secondary.main }}>
        <TextField
          label="Database Host"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Database Port"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Database User"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Database Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '1rem' }}
        >
          Save Config
        </Button>
      </Paper>
    </Box>
  );
};

export default DatabaseConfig;
