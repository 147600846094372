import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { testPromptApi as Api } from '../../services/Api'

export const chatWithPrompt = createAsyncThunk('chatWithPrompt', async (payload) => {
  try {
    const res = await axiosInstance.post(
      Api.chatWithPrompt(payload?.userId, payload?.versionId),
      payload?.chatData,
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
