import { Box, Grid, IconButton, List, styled, SwipeableDrawer, Typography } from '@mui/material'

const NavItem = styled('li')(({ theme }) => ({
  position: 'relative',
  listStyle: 'none',
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: '1.4rem',
  cursor: 'pointer',
  userSelect: 'none',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    bottom: 0,
    margin: '-5px 0',
    backgroundColor: theme.palette.topbarHover,
    visibility: 'hidden',
    transform: 'scaleX(0)',
    transition: 'all 0.4s ease-in-out 0s',
  },
  '&:hover': {
    color: theme.palette.topbarHover,
    '&:before': {
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
  },
}))

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.secondary.main,
    transition: 'transform 0.3s ease-in-out',
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  float: 'right',
  alignSelf: 'self-end',
}))

const Overlay = styled('div')(({ open, theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  backdropFilter: 'blur(5px)',
  opacity: open ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
  zIndex: open ? 1300 : -1,
}))

const DrawerBox = styled(Box)(({ theme }) => ({
  width: 250,
  height: '100%',
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignitems: 'flex-end',
}))

const DrawerList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // marginTop: theme.spacing(4),
  width: '100%',
}))
const DrawerListItem = styled(List)(({ theme }) => ({
  borderRadius: '20px',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.topbarHover,
  },
  userSelect: 'none',
}))
const DrawerListItemTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  textAlign: 'center',
}))

const MainGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: 2,
  paddingRight: 2,
  marginTop: 2,
}))

const LogoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  userSelect: 'none',
}))

const MasterTypography = styled(Typography)(({ theme, isMobile }) => ({
  color: theme.palette.textColor,
  fontWeight: 600,
  marginTop: '14px',
  fontSize: isMobile ? '1rem' : '2rem', // Base font size for small screens
  //   [theme.breakpoints.up('sm')]: {
  //     fontSize: '3rem',
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     fontSize: '4rem',
  //     alignItems: 'right',
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     fontSize: '5rem',
  //   },
  //   flexWrap: 'wrap',
  userSelect: 'none',
}))

export {
  NavItem,
  StyledSwipeableDrawer,
  StyledIconButton,
  Overlay,
  DrawerBox,
  DrawerList,
  DrawerListItem,
  DrawerListItemTypography,
  LogoGrid,
  MainGrid,
  MasterTypography,
}
