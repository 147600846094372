import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedProject: '',
  selectedProjectId: '',
  models: [],
  platforms: [],
  userProjects: {},
  projectsData: {},
  editProjectName: false,
  selectedPrompt: {},
  selectedFolderId: '',
  selectedFolderName: '',
  openFolderId: null,
  selectedPromptVersion: '',
  promptVersions: [],
}

const userProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload
    },
    setModels: (state, action) => {
      state.models = action.payload
    },
    setPlatforms: (state, action) => {
      state.platforms = action.payload
    },
    setProject: (state, action) => {
      state.projects = action.payload
    },
    setUserProjects: (state, action) => {
      state.userProjects = action.payload
    },
    setProjectsData: (state, action) => {
      state.projectsData = action.payload
    },
    setEditProjectName: (state, action) => {
      state.editProjectName = action.payload
    },
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload
    },
    setSelectedFolderName: (state, action) => {
      state.selectedFolderName = action.payload
    },
    setOpenFolderId: (state, action) => {
      state.openFolderId = action.payload
    },
    setSelectedPromptVersion: (state, action) => {
      state.selectedPromptVersion = action.payload
    },
    setPromptVersions: (state, action) => {
      state.promptVersions = action.payload
    },
    setInitialProject: (state, action) => {
      return initialState
    },
  },
})

export const {
  setSelectedProject,
  setProject,
  setUserProjects,
  setSelectedProjectId,
  setProjectsData,
  setEditProjectName,
  setSelectedFolderId,
  setSelectedPrompt,
  setSelectedFolderName,
  setOpenFolderId,
  setSelectedPromptVersion,
  setPromptVersions,
  setInitialProject,
  setModels,
  setPlatforms,
} = userProjectSlice.actions

export default userProjectSlice.reducer
