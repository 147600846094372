import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { userProjectApi as Api } from '../../services/Api'

export const getUserProjects = createAsyncThunk('getUserProjects', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.userProjectDetails(payload?.userId))
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getProjectDetails = createAsyncThunk('getProjectDetails', async (payload) => {
  try {
    const res = await axiosInstance.get(
      Api.singleProjectDetails(payload?.userId, payload?.projectId),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const createDefaultProject = createAsyncThunk('createDefaultProject', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.createDefaultProject(payload?.userId))
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const updateProjectName = createAsyncThunk('updateProjectName', async (payload) => {
  try {
    const res = await axiosInstance.patch(
      Api.updateProjectName(payload?.userId, payload?.projectId),
      {
        projectName: payload.name,
      },
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
export const deactivateProject = createAsyncThunk('deactivateProject', async (payload) => {
  try {
    const res = await axiosInstance.delete(
      Api.deactivateProject(payload?.userId, payload?.projectId),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
export const deactivateFolder = createAsyncThunk('deactivateFolder', async (payload) => {
  try {
    const res = await axiosInstance.delete(
      Api.deactivateFolder(payload?.userId, payload?.projectId, payload?.folderId),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
export const deactivatePrompt = createAsyncThunk('deactivatePrompt', async (payload) => {
  try {
    const res = await axiosInstance.delete(
      Api.deactivatePrompt(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const updateFolderName = createAsyncThunk('updateProjectName', async (payload) => {
  try {
    const res = await axiosInstance.patch(
      Api.updateFolderName(payload?.userId, payload?.projectId, payload?.folderId),
      {
        folderName: payload.name,
      },
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
export const updatePromptName = createAsyncThunk('updatePromptName', async (payload) => {
  try {
    const res = await axiosInstance.patch(
      Api.updatePromptName(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
      ),
      {
        promptName: payload.name,
      },
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
export const createProject = createAsyncThunk('createProject', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.createProject(payload?.userId), {
      projectName: payload.name,
    })
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const createFolder = createAsyncThunk('createFolder', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.createFolder(payload?.userId, payload?.projectId), {
      folderName: payload.name,
    })
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const createPrompt = createAsyncThunk('createPrompt', async (payload) => {
  try {
    const res = await axiosInstance.post(
      Api.createPrompt(payload?.userId, payload?.projectId, payload?.folderId),
      {
        promptName: payload.name,
      },
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getPromptVersions = createAsyncThunk('getPromptVersions', async (payload) => {
  try {
    const res = await axiosInstance.get(
      Api.getPromptVersions(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const savePrompt = createAsyncThunk('savePrompt', async (payload) => {
  try {
    const res = await axiosInstance.post(
      Api.savePrompt(
        payload.userId,
        payload.projectId,
        payload.folderId,
        payload.promptId,
        payload.version,
      ),
      payload.savePromptData,
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const fetchPromptPlatforms = createAsyncThunk('fetchPlatforms', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.fetchPlatforms())
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const fetchPromptModels = createAsyncThunk('fetchPlatforms', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.fetchModels(payload))
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})
