import { styled } from '@mui/material/styles'
import { Box, Divider, Menu } from '@mui/material'

export const DropdownButton = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  border: 'none',
  background: 'none',
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  '&:focus-visible': {
    boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    outline: 'none',
  },
}))

export const DropdownContent = styled(Box)(({ theme }) => ({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontSize: '0.875rem',
  padding: '6px',
  marginTop: '10px',
  maxWidth: '200px',
  borderRadius: '12px',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: `0px 4px 30px ${theme.palette.divider}`,
  zIndex: 1300,
}))

export const DropdownItemStyled = styled(Box)(({ theme }) => ({
  listStyle: 'none',
  padding: '8px',
  borderRadius: '8px',
  cursor: 'pointer',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:focus': {
    outline: `3px solid ${theme.palette.info.light}`,
    backgroundColor: theme.palette.action.hover,
  },
  color: theme.palette.text.primary,
}))

export const DropdownPopper = styled('div')({
  position: 'absolute',
  zIndex: 1300,
  width: '200px',
  right: 10,
})

export const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    marginTop: '10px',
    borderRadius: '12px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    width: '200px',
  },
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    paddingBottom: 0,
    paddingTop: 0,
  },
})

export const MenuItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const StyledDivider = styled(Divider)(() => ({
  marginTop: '0px !important',
  marginBottom: '0px !important',
}))
