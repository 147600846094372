import { Typography, Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

// Styled Components
const StyledBox = styled(Box)(({ theme, isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 10px 0px 10px',
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  height: '100vh',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  fontSize: '300%',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))

const StyledContentTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  fontSize: '200%',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))

const StyledContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: theme.spacing(5),
  borderRadius: 30,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const ColorText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,

  [theme.breakpoints.down('md')]: {},
}))

export {
  StyledBox,
  StyledTypography,
  StyledButton,
  ColorText,
  StyledContent,
  StyledContentTypography,
}
