import axios from 'axios'
import { getStoredData } from '../decodeUser'
import { authApi } from '../../services/Api'
import { Navigate } from 'react-router-dom'
import { navigationRoutes } from '../../router/Navigation'
import { toast } from 'react-toastify'

export const refreshToken = async () => {
  try {
    const { refreshToken } = getStoredData()
    if (refreshToken) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${authApi.refreshToken}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        },
      )

      const { access_token } = response.data

      // Update local storage with new tokens
      const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
      localStorage.setItem(STORAGE.TOKEN, btoa(access_token))

      return access_token
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
    const STORAGE = JSON.parse(process.env.REACT_APP_STORAGE)
    localStorage.removeItem(STORAGE.TOKEN)
    localStorage.removeItem(STORAGE.REFRESH)
    localStorage.removeItem(STORAGE.USER_INFO)
    window.location.href = navigationRoutes.main.landingPage
    toast.error("Session expired! Please log in again.");
  }
  return null
}
