import { Box, IconButton, styled } from '@mui/material'

const SettingsButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

const MemorySettingsPopover = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(8),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
  zIndex: 1,
  Width: '200px',
}))

export { SettingsButton, MemorySettingsPopover }
