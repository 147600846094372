import React, { useRef, useState } from 'react'
import { Box, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import { MessageBox, MessageTypography, NameTypography } from './MessageStyles'
import { Images } from '../../assets/image'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteMessage,
  editMessage,
  deleteMessageCompare,
  editMessageCompare,
} from '../../redux/slice/Conversation'
import CancelIcon from '@mui/icons-material/Cancel'

const Message = ({
  role,
  message,
  userInfo,
  loading,
  model,
  index,
  id,
  compare,
  isPublished,
  isFirst,
  prevMsgId,
  nextMsgId,
}) => {
  const { t, ready } = useTranslation()
  const isUser = role === 'USER'
  const modelImages = {
    claudeai: Images.claudeLogo,
    openai: Images.opeanAiLogo,
    gemini: Images.geminiLogo,
  }

  const theme = useTheme()
  const imageSrc = model && modelImages[model]
  const dispatch = useDispatch()
  const textFieldRef = useRef(null)

  const [hover, setHover] = useState(false)

  const [deleteHover, setDeleteHover] = useState(false)

  const { selectedModel, selectedModelCompare } = useSelector((state) => state.conversations)

  const handleMessageBoxClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus()
    }
  }

  const Theme = useTheme()

  const handleDelete = (otherId) => {
    if (compare) {
      dispatch(deleteMessageCompare(id))
      if (selectedModelCompare === 'claudeai') {
        dispatch(deleteMessageCompare(otherId))
      }
    } else {
      dispatch(deleteMessage(id))
      if (selectedModel === 'claudeai') {
        dispatch(deleteMessage(otherId))
      }
    }
  }

  const handleEdit = (updatedMessage) => {
    if (compare) {
      dispatch(editMessageCompare({ id, updatedMessage }))
    } else {
      dispatch(editMessage({ id, updatedMessage }))
    }
  }

  return (
    <>
      <MessageBox
        assistant={!isUser}
        onClick={handleMessageBoxClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          pointerEvents: isPublished ? 'none' : 'auto',
          opacity: isPublished ? 0.7 : 1,
        }}
        deleteHover={deleteHover}
      >
        {isUser ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{ width: '100%', justifyContent: 'center' }}
          >
            <img
              src={userInfo?.userProfile}
              alt="Profile"
              style={{ width: 40, borderRadius: '50%', marginRight: 15 }}
            />
            <Box flexGrow={1}>
              <NameTypography>{userInfo?.userName}</NameTypography>
              <TextField
                fullWidth
                inputRef={textFieldRef}
                sx={{ width: '100%' }}
                variant="standard"
                value={typeof message === 'string' ? message : JSON.stringify(message, null, 2)}
                multiline
                InputProps={{
                  disableUnderline: true, // <== added this
                }}
                onChange={(e) => handleEdit(e.target.value)}
              />
            </Box>
            {hover && !isFirst && (
              <IconButton
                onClick={() => handleDelete(nextMsgId)}
                onMouseEnter={() => {
                  console.log('Mouse entered delete button user')
                }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{ width: '100%', justifyContent: 'center' }}
          >
            <img
              src={theme.palette.mode === 'light' ? Images.logo : Images.whiteLogo}
              alt="Profile"
              style={{
                width: 40,
                height: 25,
                borderRadius: '50%',
                marginRight: 15,
              }}
            />
            <Box flexGrow={1}>
              <NameTypography>
                {ready ? t('studio.studioInput.assistance') : 'Assistant'}
              </NameTypography>
              <TextField
                fullWidth
                inputRef={textFieldRef}
                variant="standard"
                value={typeof message === 'string' ? message : JSON.stringify(message, null, 2)}
                multiline
                InputProps={{
                  disableUnderline: true, // <== added this
                }}
                onChange={(e) => handleEdit(e.target.value)}
              />
            </Box>
            {hover && (
              <IconButton
                onClick={() => handleDelete(prevMsgId)}
                // onMouseEnter={() => {
                //   setDeleteHover(true)
                //   console.log('Mouse entered delete button assistant', indexedId)
                // }}
                // onMouseLeave={() => {
                //   setDeleteHover(false)
                //   console.log('Mouse left delete button assistant', indexedId)
                // }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </MessageBox>
    </>
  )
}

export default Message
