// SettingsPage.js
import React, { useState } from 'react'
import { Box, CssBaseline, Toolbar, useTheme } from '@mui/material'
// import Sidebar from '../../components/settings/Sidebar'
import KeyConfiguration from '../../components/settings/KeyConfiguration'
import BillingUsage from '../../components/settings/BillingUsage'
import Subscription from '../../components/settings/Subscription'
import DatabaseConfig from '../../components/settings/DatabaseConfig'
import TopBar from '../../components/TopBar'
import { USEISMOBILE } from '../../constants/Constants'
// import Sidebar from '../../components/settings/sidebar'

const SettingsPage = () => {
  const [selectedSection, setSelectedSection] = useState('keyConfiguration')
  const isMobile = USEISMOBILE()
  const Theme = useTheme()

  const renderContent = () => {
    switch (selectedSection) {
      case 'keyConfiguration':
        return <KeyConfiguration />
      case 'billingUsage':
        return <BillingUsage />
      case 'subscription':
        return <Subscription />
      case 'databaseConfig':
        return <DatabaseConfig />
      default:
        return <KeyConfiguration />
    }
  }

  return (
    <>
      <CssBaseline />
      <TopBar />
      <Toolbar />
      <Box display={'flex'} height={`calc(100vh - ${64}px)`}>
        <Box display={isMobile ? 'none' : 'flex'}>
          {/* <Sidebar onSelect={setSelectedSection} selected={selectedSection} /> */}
        </Box>
        <Box
          flexGrow={1}
          padding={3}
          sx={{ backgroundColor: Theme.palette.mode === 'dark' ? 'black' : 'white' }}
        >
          {renderContent()}
        </Box>
      </Box>
    </>
  )
}

export default SettingsPage
