import React, { useState } from 'react'
import { FormControl } from '@mui/material'
import Swal from 'sweetalert2'
import {
  CustomSelect,
  MenuItemStyled,
  CustomPopper,
  StyledDiv,
  StyledFormControl,
} from './dropDown'
import { useTheme } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { useSelect } from '@mui/base'
import {
  setJsonMode,
  setJsonModeCompare,
  setMessages,
  setMessagesCompareInitial,
  setMessagesInitial,
  setResponseFormat,
  setResponseFormatCompare,
} from '../../redux/slice/Conversation'
import { MessageSharp } from '@mui/icons-material'
import { checkMessageSequence } from '../../util/helpers'

const AIModelDropdown = ({
  menuList,
  selectedModel,
  setSelected,
  icon,
  width = '200px',
  id,
  buttonHeight = 'auto',
  buttonBgColor,
  buttonTextColor,
  menuHoverColor,
  dropdownBgColor,
  iconBgColor,
  iconColor,
  height = 'auto',
  disabled = 'false',
  category,
  component,
  isPublished = false,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [open, setOpen] = useState(false) // State to manage dropdown visibility

  const {
    selectedModelCompare,
    // selectedModel,
    messages,
    messagesCompare,
    // isPublished,
    systemValue,
    systemValueCompare,
    chatSummary,
    variables,
    variablesCompare,
    jsonMode,
    jsonModeCompare,
    responseFormat,
    responseFormatCompare,
  } = useSelector((state) => state.conversations)
  const Theme = useTheme()

  const confirmation = (event) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Switching to Claude AI will result in data loss. Do you want to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, switch to Claude AI',
    }).then((result) => {
      if (result.isConfirmed) {
        if (component === 1) {
          dispatch(setMessagesInitial([]))
        }
        if (component === 2) {
          dispatch(setMessagesCompareInitial([]))
        }
        if (category) {
          setSelected(event.target.value)
        } else {
          dispatch(setSelected(event.target.value))
        }
        setOpen(false)
      }
    })
  }

  const clearSchema = (component, event) => {
    if (component === 1) {
      dispatch(setJsonMode(false))
      dispatch(setResponseFormat(''))
    } else if (component === 2) {
      dispatch(setJsonModeCompare(false))
      dispatch(setResponseFormatCompare(''))
    }

  }
  const SchemaWarning = (component, model, event) => {
    return new Promise((resolve) => {
      const modalKey = `schemaWarningShown_doNotShow`;
      const doNotShow = localStorage.getItem(`${modalKey}`);
      if (doNotShow === 'true') {
        clearSchema(component, event);
        resolve(true);
        return;
      }

      Swal.fire({
        title: 'Configuration Warning',
        text: 'Current Configuration is not supported for ' + model + '. Clear Configuration and continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: Theme.palette.primary.main,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Close',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: 'Do not show this warning again'
      }).then((result) => {
        if (result.isConfirmed) {
          clearSchema(component, event);
          if (result.value === 1) {
            localStorage.setItem(`${modalKey}`, 'true');
          }
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  const handleClaudeChange = (event) => {
 // For component 1
 if (component === 1) {
  if (messages.length > 0 && !checkMessageSequence(messages)) {
    confirmation(event)
  } else if (!checkMessageSequence(messages)) {
    dispatch(setMessagesInitial([]))
  } else {
    if (category) {
      setSelected(event.target.value)
    } else {
      dispatch(setSelected(event.target.value))
    }
  }
}

// For component 2
if (component === 2) {
  if (messagesCompare.length > 0 && !checkMessageSequence(messagesCompare)) {
    confirmation(event)
  } else if (!checkMessageSequence(messagesCompare)) {
    dispatch(setMessagesCompareInitial([]))
  } else {
    if (category) {
      setSelected(event.target.value)
    } else {
      dispatch(setSelected(event.target.value))
    }
  }
}
  }
  const handleChange = async (event) => {

    if(!category) {
      if(!await SchemaWarning(component, event.target.value, event)) {
        return
      }
    }

    if (event.target.value === 'claudeai') {
      handleClaudeChange(event)
    } else {
      // For other models, proceed as normal
      if (category) {
        setSelected(event.target.value)
      } else {
        // clearSchema(component)
        dispatch(setSelected(event.target.value))
      }
      setOpen(false)
    }

  }

  return (
    <StyledFormControl variant="outlined" width={width}>
      <CustomSelect
        id={id}
        value={selectedModel}
        onChange={handleChange}
        open={open && !isPublished} // Prevent opening if isPublished is true
        onClick={() => !isPublished && setOpen(!open)} // Toggle dropdown only if not published
        IconComponent={() => (
          <div onClick={() => !isPublished && setOpen(!open)}>
            {' '}
            <StyledDiv>{icon}</StyledDiv>
          </div>
        )}
        autoWidth
        displayEmpty
        bgcolor={buttonBgColor}
        color={buttonTextColor}
        height={buttonHeight}
        PopperComponent={(props) => <CustomPopper {...props} dropdownBgColor={dropdownBgColor} />}
        style={{ height: height }}
        sx={{
          paddingRight: 0,
        }}
        // disabled={disabled}
        disabled={component === 1 && isPublished}
      >
        {menuList?.map((model, index) => (
          <MenuItemStyled
            key={index}
            value={model}
            hoverColor={menuHoverColor}
            selectedColor={buttonBgColor}
            firstIndex={index === 0}
            lastIndex={index === menuList?.length - 1}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {model}
            </span>
          </MenuItemStyled>
        ))}
      </CustomSelect>
    </StyledFormControl>
  )
}

export default AIModelDropdown
