import React from 'react'
import { Grid } from '@mui/material'
import CommonStyles from '../../assets/styles/CommonStyles'

const ComingSoon = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 6, userSelect: 'none' }}
    >
      <CommonStyles.ComingSoon />
    </Grid>
  )
}

export default ComingSoon
