import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  styled,
} from '@mui/material'

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '20px',
  '&.Mui-expanded': {
    minHeight: '20px',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: '10px',
    marginBottom: '10px',
    '&.Mui-expanded': {
      marginTop: '10px',
      marginBottom: '15px',
    },
  },
}))

const SystemMessageBox = styled(Box)(({ theme }) => ({
  // position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
}))

const SystemPromptBox = styled(Box)(() => ({
  border: 0,
  flex: 9,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  p: 2,
}))

const SystemTextField = styled(TextField)(() => ({
  flex: '0 1 auto',
  position: 'relative',
}))

const MainBox = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  scrollBehavior: 'smooth',
}))

const SystemAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 50,
}))

const SystemAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: 0,
}))

const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}))

export {
  MainBox,
  CustomAccordionSummary,
  SystemMessageBox,
  SystemPromptBox,
  SystemTextField,
  SystemAccordion,
  SystemAccordionDetails,
  LoadingBox,
}
