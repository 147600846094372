export const Icons = {
  microsoftLogo: require('./microsoft.svg').default,
  profilePic: require('./profilePic.png'),
  googleIcon: require('./googleIcon.svg').default,
}

// export const Gif = {
//   loading: require('./loginGif.gif'),
// }

export const Images = {
  // loginBG: require('./loginBG.jpg'),
  logo: require('./companyLogoOnly.png'),
  whiteLogo: require('./companyLogoOnlyWhite.png'),
  Footerlogo: require('./companyLogoWIthName.png'),
  logoWhite: require('./companyLogoOnlyWhite.png'),

  heroImage: require('./hero.svg').default,
  aboutImage: require('./about.svg').default,
  feature1: require('./1.jpg'),
  feature2: require('./2.jpg'),
  feature3: require('./3.jpg'),
  feature4: require('./4.jpg'),
  feature5: require('./5.jpg'),
  feature6: require('./6.jpg'),
  feature7: require('./7.jpg'),
  featureOverview: require('./featureOverview.png'),
  aside: require('./aside.svg').default,
  aside2: require('./aside2.svg').default,
  aside3: require('./aside3.svg').default,
  aside4: require('./aside4.svg').default,
  pageNotFound: require('./pageNotFound.png'),
  StatusCode200: require('./StatusCode200Image.jpg'),
  StatusCode201: require('./StatusCode201Image.jpg'),
  AiKey: require('./AiKeyImage.jpg'),
  ComingSoon: require('./comingSoon.jpg'),

  opeanAiLogo: require('./opeanaiLogo.png'),
  claudeLogo: require('./claudeLogo.png'),
  geminiLogo: require('./geminiLogo.png'),
}
