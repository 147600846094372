import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, Stack, Toolbar, Button, styled } from '@mui/material'

import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import MenuIcon from '@mui/icons-material/Menu'

import { USEISMENU, USEISMOBILE } from '../constants/Constants'

import CommonStyles from '../assets/styles/CommonStyles'

import ProjectMenu from './projectMenu'
import ProfileMenu from './profileMenu'
import LanguageSwitcher from './languageSwitcher'

import { useTheme } from '@emotion/react'
import { navigationRoutes } from '../router/Navigation'
import SystemTheme from './systemTheme'

const TopBar = ({ toggleDrawer }) => {
  const location = useLocation()
  const isMobile = USEISMOBILE()
  const isMenu = USEISMENU()
  const theme = useTheme()
  const navigate = useNavigate()

  const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.textColor,
  }))

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.studio.topBar, zIndex: 1 }}>
      <Toolbar>
        <Stack direction={'row'} alignItems={'center'} sx={{ flexGrow: 1 }}>
          {/* Conditionally render StyledMenuIcon based on the current route */}
          {isMenu && location.pathname !== navigationRoutes.user.settings && (
            <Stack>
              <StyledMenuIcon onClick={() => toggleDrawer(true)} />
            </Stack>
          )}
          {theme.palette.mode === 'light' ? (
            <Box
              onClick={() => navigate(navigationRoutes.user.workspace)}
              sx={{ cursor: 'pointer' }}
            >
              <CommonStyles.CompanyMainLogo />
            </Box>
          ) : (
            <Box
              onClick={() => navigate(navigationRoutes.user.workspace)}
              sx={{ cursor: 'pointer' }}
            >
              <CommonStyles.CompanyLogoWhite onClick={() => navigate('/')} />
            </Box>
          )}

          {/* <Typography variant="h6" noWrap component="div">
            Promptstudio.dev
          </Typography> */}

          <ProjectMenu />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {/* Sidebar icon for settings page */}
          {isMobile && location.pathname === '/settings' && (
            <Button variant="contained">
              <MenuRoundedIcon />
            </Button>
          )}
          <LanguageSwitcher />
          <SystemTheme />
          <ProfileMenu Settings={true} Logout={true} />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
