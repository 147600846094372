import { Drawer, List, ListItem, ListSubheader, styled, Typography } from '@mui/material'
import { LEFT_DRAWER_WIDTH } from '../../constants/Constants'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 300,
  flexShrink: 0,
  width: LEFT_DRAWER_WIDTH,
  [`& .MuiDrawer-paper`]: {
    width: LEFT_DRAWER_WIDTH,
    boxSizing: 'border-box',
  },
  userSelect: 'none',
}))

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  padding: '4px',
  backgroundColor: theme.palette.studio.leftBar,
  // paddingTop: '64px',
  overflow: 'auto',
  height: '100%',
  userSelect: 'none',
}))

const Subheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.studio.leftBar,
  color: theme.palette.studio.leftTitle,
  fontSize: '1.1rem',
  fontWeight: 'bold',
  borderRadius: '8px',
  marginY: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
  userSelect: 'none',
}))

const Item = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  borderRadius: '8px',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  marginY: '6px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  userSelect: 'none',
}))

const LightText = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: '550',
  maxWidth: '200px',
  userSelect: 'none',
}))

export { StyledDrawer, StyledList, Item, LightText, Subheader }
