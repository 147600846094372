import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { userSettings as Api } from '../../services/Api'

export const getUserKeys = createAsyncThunk('getApiKeys', async (payload) => {
  try {
    const res = await axiosInstance.get(Api.getUserKeys(payload.userId))
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const addUserKey = createAsyncThunk('addUserKey', async (payload) => {
  try {
    const res = await axiosInstance.post(Api.addUserKey(payload.userId), payload.obj)
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const updateUserKeys = createAsyncThunk('updateUserKeys', async (payload) => {
  try {
    const res = await axiosInstance.patch(Api.updateUserKeys(payload.userId), payload.obj)
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})

export const deleteUserKeys = createAsyncThunk('deleteUserKeys', async (payload) => {
  try {
    const res = await axiosInstance.delete(Api.deleteUserKeys(payload.userId), {
      data: payload.obj,
    })
    if (res?.status === 201 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else if (res?.status === 200 && res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error)
    return error.message
  }
})
