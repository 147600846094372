import { createSlice } from '@reduxjs/toolkit'

const initialStates = {
  isNewUser: false,
  isAIkeyConfig: false,
  dialogOpen: false,
  systemTheme: 'system',
  isLogged: false,
}

const common = createSlice({
  name: 'Common',
  initialState: initialStates,
  reducers: {
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload
    },
    setIsKeyConfig: (state, action) => {
      state.isAIkeyConfig = action.payload
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload
    },
    setSystemTheme: (state, action) => {
      state.systemTheme = action.payload
    },
    setLogIn: (state, action) => {
      state.isLogged = action.payload
    },
  },
})

export const {
  setIsNewUser,
  setIsKeyConfig,
  setIsPublished,
  setDialogOpen,
  setSystemTheme,
  setLogIn,
} = common.actions

export default common.reducer
